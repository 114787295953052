// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController"
import User from "controllers/ModelControllers/UserModelController"
import self_request from "assets/graphql/Users/self.graphql.json"
import languages from "assets/languages.json"
import CompanyModelController from "controllers/ModelControllers/CompanyModelController.jsx"
import self_update from "assets/graphql/Users/self.update.mutation.graphql.json"

export default class SettingsPageController extends _viewController {
    constructor(props) {
        super(props)
        this.company = new CompanyModelController({params: {parent: this,
                                                                is_view: true,
                                                                controller: this}})
        this.user = new User({params: {parent: this, 
                                        controller: this, 
                                        is_view: true}})
        this.requests   = [{request: self_request, callback: {f: this.load_user, p: {name: "self"}}, endpoint: "users"}]
    }
    get user_copy() {
        return this.view.state.data.user_copy
    }
    get_user_data() {
        if(JSON.stringify(this.view.state?.data?.user) === "{}") {
            let user = this?.view?.state?.data?.user
            if(user === undefined) { return }
            user = JSON.parse(JSON.stringify(user))
            this.view.state.data.user = user
        }
    }
    save_user_data({caller, results, param}) {
        caller.view.state.data.user = caller.getCopy(results.data[param])
        caller.view.state.data.user_copy = caller.getCopy(results.data[param])
        caller.view.state.cache = caller.getCopy(caller.view.state.data[param])
        caller.view.forceUpdate()
    }
    async load_user({caller, params, results}) {
        let target = (params?.name === "users") ? "updateSelf" : "self"
        if(results?.errors === undefined && results?.data?.[target] !== undefined) {
            caller.view.state.data.user = caller.getCopy(results.data[target])
            caller.view.state.cache = caller.getCopy(caller.view.state.data.user)
            caller.view.forceUpdate()
            if(target === "updateSelf") {
                await caller.context.get_self({caller: caller?.context?.app?.controller, update: true})
                if(caller.user_copy.settings.menu_open !== caller.user.settings.menu_open){
                    caller.sidebar.controller.toggle_sidebar()
                }
            }
            caller.view.state.data.user_copy = caller.getCopy(results.data[target])
        }
    }
    save() {
        this.view.state.save = true
        let summary = JSON.parse(JSON.stringify(this.user.summary))
        if(summary?.roles !== undefined && summary?.roles !== null) {
            delete summary.roles
        }
        let data = this.toUnquotedJSON(summary)
        let body = JSON.parse(JSON.stringify(self_update))
        console.log(data)
        body.query = body.query.replace("input:", "input: "+data)
        this.api.users.ask({caller: this, params: {body: body}, callback: {f: this.load_user}})
    }
    reset() {
        if(this?.view?.state?.current_image){
            this.view.state.preview = false
            delete this?.view?.state?.current_image
        }
        // this.view.state.preview = false
        let user = JSON.parse(JSON.stringify(this.view.state.data.user_copy))
        this.view.state.data.user = user
        this.user._sync({data: user})
    }
    resolveCache() {
        if (this.view.state?.cache !== undefined) {
            this.view.state.context.user    = this.getCopy(this.view?.state?.cache)
            this.setState({key: "data", param: "user", value: this.getCopy(this.view?.state?.cache)})
            this.view.forceUpdate()
        }
    }
    create_lang_array() {
        let langs = []
        Object.keys(languages).forEach((lang, index) => {
            let lang_item = {label: languages[lang], value: lang}
            langs.push(lang_item)
        })
        return langs
    }
    // follow_on_dropdown({key, param, value}) {
    //     // console.log(key, param, value)
    //     this.save()
    // }
}
