// GENERAL REACT MODULES
import _baseComponent from "./_baseComponent";

export default class _baseApiObject extends _baseComponent {
    constructor(props) {
        super(props)
	    this.state.cache = []
        this.state.data = {}
        this.state.keys = {}
        this.test_data  = undefined
        this.rules_file = {}
        this.snackbar_config = {
            success: { msg: "Your data has been saved successfully.", show: false }, 
            error: { msg: "Oops! Something went wrong!", show: true }
        }
    }
    load_from_test({id, params}) {
        let data = this.test_data[id]
        return data
    }
    load_from_api({id, params}) {
        let data = this.schema
        if (this.test_data !== undefined) {
            if (this.state.parent.mode()) {
                data = this.test_data[id]
            }
        }
        return data
    }
    load_from_cache({id, params}) {
        let data = this.state.data[id]
        if (data === undefined) {
            data = this.load_from_api({id, params})
            if (data !== undefined) {
                data.expiry = Math.floor((new Date()).getTime() / 1000)
                this.state.data[id] = data
            }
        }
        if (data !== undefined) {
            data = this.load_sub_objects(data)
            data.uuid = id
        }
        return data
    }
    load_sub_objects(data) { 
        return data
    }
    async call_post({caller, callback, params, body, rule_set}) {
        let enforce_rules = this.is_mutation(params)
        if(enforce_rules && rule_set !== undefined) {
            let data = this.get_query_input(body?.query)
            let result = this.validate_data({data: data, rule_set: rule_set})
            if(result.error) {
                if(typeof caller?.show_snackbar === "function") {
                    caller?.show_snackbar({msg: result.messages[0], status: "error"}) 
                } else if (typeof caller?.controller?.show_snackbar === "function") {
                    caller?.controller?.show_snackbar({msg: result.messages[0], status: "error"}) 
                }
                return result 
            }
        }
        return await this.parent.post({caller: caller, callback: callback, params: params, body: body})
    }
    async create({caller, id, params, callback, json}) {
        callback = this.configure_callback({callback: callback, params: params})
        // let results = await this.parent.post({caller: caller, callback: callback, params: params, body: params?.body})
        let rule_set = this.determine_rule_set({rule_set: params?.rule_set, operation: "create"})
        let results = await this.call_post({caller: caller, callback: callback, params: params, body: params?.body, rule_set: rule_set})
        return results?.data?.[Object.keys(results?.data)?.[0]]?.id
    }
    update({id, params, values, json}) {
        let data = this.get({id: id})
        if (json !== undefined) {
            data = json
        } else {
            if (values !== undefined) {
                Object.keys(values).map((key, value) => {
                    if (Array.isArray(data[key])) {
                        data[key].push(values[key])
                    } else {
                        data[key] = values[key]
                    }
                    return undefined
                })
            }
        }
        this.state.data[id] = data
        return this.get({id: id})
    }
    delete({id, params}) {

    }
    async get({id, params}) {
        let data = this.schema
        // data = this.load_from_cache({id: id, params: params})
        // data = await this.parent.post({body: params?.body})
        data = await this.call_post({body: params?.body})
        return data
    }
    async ask({caller, id, params, callback, nocache}) {
        callback = this.configure_callback({callback: callback, params: params})
        if (caller?._isMounted) {
            let response = this.schema
//             if (!nocache || nocache === undefined) {
//                 this.state.cache = this.state.cache.filter((cache) => (cache.ts-Date.now()) > -300000 )
//                 let decomposed = this.decompose_params(params?.body?.query)
//                 let exists = undefined
//                 if (decomposed !== undefined) {
// //                  exists = this.state.cache.filter((cache) => (cache?.[decomposed[0]])?.toString().toLowerCase().startsWith(decomposed[1]?.toString().toLowerCase()))
//                 }
//                 if (exists?.length > 0) {
// //                  let results = {
// //                      data: {}
// //                  }
// //                  results.data[params?.data?.endpoint] = exists
// //                  return results
//                 }
//             }
            // response = await this.parent.post({caller: caller, callback: callback, params: params, body: params?.body})
            response = await this.call_post({caller: caller, callback: callback, params: params, body: params?.body, rule_set: params?.rule_set})
            // if (nocache || nocache === undefined) {
            //     if (response?.data !== undefined) {

            //         let key = Object.keys(response.data)?.[0]
            //         let data = response.data?.[key]
            //         console.log(data)
            //         Object.keys(response.data).map((k, i) => {
            //             if (Array.isArray(response.data[k])) {
            //                 response.data[k].map((obj, index) => {
            //                     let exists = this.state.cache.filter((cache) => cache.id === obj.id)
            //                     if (exists.length === 0) {
            //                         obj.ts = Date.now()
            //                         this.state.cache.push(obj)
            //                     }
            //                 })
            //             }
            //         })
            //     }
	        // }
            return response
        }
        return undefined
    }
    decompose_params(params) {
        var regExp = /\(([^)]+)\)/;
        if (params !== undefined) {
            let matched = params.match(regExp)
            if (matched !== undefined && matched !== null) {
                let decomposed = (params.match(regExp)[1]).split(" ")
                decomposed.map((o, i) => {
                    decomposed[i] = o.replaceAll(':', '').replaceAll('%', '').replaceAll('"', '')
                    return
                })
                return decomposed
            }
        }
        return undefined
    }
    get_schema() {
        return this.schema
    }
    append_values(values) {
        let append_string = " "
        if (values !== undefined) {
            Object.keys(values).map((k, i) => {
                append_string = append_string+', '+k+':'+((!isNaN(values[k])) ? values[k]: '"'+values[k]+'"')
                return
            })
        }
        return append_string
    }
    configure_callback({callback, params}) {
        if (callback === undefined) {
            callback = {}
        }
        if (typeof callback?.p === "object") {
            if(callback.p?.name === undefined) {
                callback.p.name = this.name.toLowerCase()
            }
        } else {
            callback.p = {
                name: this.name.toLowerCase()
            }
        }
        callback.p.sb = this.configure_snackbar({snackbar: callback.p?.sb, params: params})
        return callback
    }
    configure_snackbar({snackbar, params}) {
        let sb_config = JSON.parse(JSON.stringify(this.snackbar_config))
        Object.keys(sb_config).map((status) => {
            Object.keys(sb_config[status]).map((key) => {
                if(snackbar?.[status]?.[key] !== undefined) {
                    sb_config[status][key] = snackbar[status][key]
                } else {
                    sb_config[status].show = this.is_mutation(params)
                }
                return
            })
        })
        return sb_config
    }
    is_mutation(params) {
        if(params?.body?.query?.includes("mutation")) {
            return true
        }
        return false
    }
    get_query_input(query) {
        query = (query.substring(query.indexOf("(") + 1, query.lastIndexOf(")"))).replace("input:", '').trim()
        let input = JSON.parse(this.fixUnquotedKeys(query))
        return input
    }
    fixUnquotedKeys(jsonString) {
        return jsonString.replace(/([{,]\s*)([a-zA-Z_][a-zA-Z0-9_]*)\s*:/g, '$1"$2":')
    }
    determine_rule_set({rule_set, operation}) {
        if(operation === undefined) { return }
        if(rule_set === undefined) { rule_set = this.rules_file }
        // if(rule_set?.[operation] === undefined) { return }
        return rule_set?.[operation]
    }
    validate_data({data, rule_set}) {
        let messages = this.validate_rule({data: data, rule: rule_set})
        let error = false
        if(messages.length > 0) { error = true }
        return {error: error, messages: messages}
    }
    is_object(data) {
        if(typeof data === "object" && data !== null) {
            return true
        }
        return false
    }
    validate_rule({data, rule}) {
        let rule_keys = Object.keys(rule)
        let data_keys = Object.keys(data)
        let checks = []
        for(let el of rule_keys) {
            if(!data_keys.includes(el)) {
                return `Missing field ${el}.`
            }
            checks.push(this.validate_field({key: el, data: data[el], rule: rule[el]}))
        }
        return checks.flat(Infinity).filter(el => typeof el === "string")
    }
    validate_field({key, data, rule}) {
        if(this.is_object(rule)) {
            if(this.is_object(data)) { 
                if(Array.isArray(data)) {
                    let checks = []
                    for(let el of data) {
                        checks.push(this.validate_field({key: key, data: el, rule: rule}))
                    }
                    return checks
                } else {
                    return this.validate_rule({data: data, rule: rule})
                }
            } else {
                if(Array.isArray(rule)) {
                    let checks = []
                    for(let el of rule) {
                        checks.push(this.validate_field({key: key, data: data, rule: el}))
                    }
                    return checks
                } else {
                    // console.log(`-- data[${key}] is NOT an object but rule[${key}] IS an object --`)
                    return `Please fix ${key}.`
                }
            }
        } else {
            if(typeof data !== rule) { 
                // console.log(`--- data[${key}]: [${data}] is not same type as rule[${key}]: [${rule}] ---`)
                return `The field ${key} needs to be a ${rule}.`
            }
            // console.log(`--- data[${key}]: [${data}] is rule[${key}] type: [${rule}] ---`)
            return true
        }
    }
}
