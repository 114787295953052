// GENERAL REACT MODULES

// ALL OTHER MODULES
import _ModelController from "./_ModelController";
import RoleModelController from "controllers/ModelControllers/RoleModelController";

export default class CompanyModelController extends _ModelController {
    constructor(props) {
        super(props)
        this.role = new RoleModelController({params: {parent: this, 
                                                controller: this.controller,
                                                is_view: true}})
    }
    get motor_carrier() {
	    return this.data?.motor_carrier
    }
    set motor_carrier(value) {
        this.controller.setState({key: "data", param: "company.motor_carrier", value: value})
    }
    get ein() {
        return this.data?.ein
    }
    set ein(value) {
        this.controller.setState({key: "data", param: "company.ein", value: value})
    }
    get trailer_types() {
        return this.data?.trailer_types
    }
    get trailer_types_flat() {
        let flat = ""
        this.data?.trailer_types?.forEach((type, index) => {
            flat = flat+type.name+", "
        })
        return flat
    }
    set trailer_types(value) {
        this.controller.setState({key: "data", param: "company.trailer_types", value: value})
    }
    get operating_areas() {
        return this.data?.operating_areas
    }
    get operating_areas_flat() {
        let flat = ""
        this.data?.operating_areas?.forEach((state, index) => {
            flat = flat+state.state+", "
        })
        return flat
    }
    set operating_areas(value) {
        this.controller.setState({key: "data", param: "company.operating_areas", value: value})
    }
    get dot_number() {
        return this.data?.dot_number
    }
    set dot_number(value) {
        this.controller.setState({key: "data", param: "company.dot_number", value: value})
    }
    get socials() {
        return this.data?.socials
    }
    set socials(value) {
        this.controller.setState({key: "data", param: "company.socials", value: value})
    }
    get website() {
        // return this.data?.website
        return this.socials?.website
    }
    set website(value) {
        // this.data.website = value
        this.controller.setState({key: "data", param: "company.socials.website", value: value})
    }
    get facebook() {
        return this.socials?.facebook
    }
    set facebook(value) {
        // this.socials.facebook = value
        this.controller.setState({key: "data", param: "company.socials.facebook", value: value})
    }
    get twitter() {
        // return this?.company?.socials?.twitter
        return this.socials?.twitter
    }
    set twitter(value) {
        // this.company.socials.twitter = value
        this.controller.setState({key: "data", param: "company.socials.twitter", value: value})
    }
    get scac_code() {
        return this.data?.scac_code
    }
    set scac_code(value) {
        this.controller.setState({key: "data", param: "company.scac_code", value: value})
    }
    get safety_rating() {
        return this.data?.safety_rating
    }
    set safety_rating(value) {
        this.controller.setState({key: "data", param: "company.safety_rating", value: value})
    }
    get number_of_trucks() {
	    return this.data?.number_of_trucks
    }
    set number_of_trucks(value) {
        this.controller.setState({key: "data", param: "company.number_of_trucks", value: value})
    }
    get number_of_trucks_labeled() {
        if (this.number_of_trucks !== "" && this.number_of_trucks !== null && this.number_of_trucks !== undefined) {
            return this.number_of_trucks + " Trucks"
        }
        return ""
    }
    get ids() {
        return this.data?.company_ids
    }
    get url() {
        return this.data?.website
    }
    get image() {
        return this.data?.image
    }
    rem_obj(v) {
        return (typeof v !== "string") ? "" : v
    }
    get name() {
        if (this.data?.name !== undefined && this.data?.name !== "") {
            return this.rem_obj(this.data.name)
        }
        let selected = this.controller.getState({key: "search", param: "company.selected_value"})
        if (selected?.name !== undefined && Object.keys(selected).length > 0) {
            return this.rem_obj(selected.name)
        }
        return this.rem_obj(this.controller.getState({key: "search", param: "company.search_value"}))
    }
    // May need to remove this if it causes issues with the search functionality?
    set name(value) {
        this.data.name = value
    }
    get email() {
        return this.data?.email
    }
    set email(value) {
	    this.setState({param: "email", value: value})
    }
    get phone() {
        return this.data?.phone
    }
    set phone(value) {
        this.controller.setState({key: "data", param: "company.phone", value: value})
    }
    get fax() {
        return this.data?.fax
    }
    set fax(value) {
        this.controller.setState({key: "data", param: "company.fax", value: value})
    }
    get address() {
        return this.data?.address?.address
    }
    set address(value) {
        this.controller.setState({key: "data", param: "company.address.address", value: value})
    }
    get address_2() {
        if (typeof this.data?.address?.address_2 === "string") {
            return this.data?.address?.address_2
        }
    }
    set address_2(value) {
        this.controller.setState({key: "data", param: "company.address.address_2", value: value})
    }
    get zip() {
        // return this.data?.address?.location?.zip
	    return (this.data?.address?.location?.notnull("zip")) ? String(this.data.address.location.zip): ""
    }
    set zip(value) {
        this.controller.setState({key: "data", param: "company.address.location.zip", value: String(value)})
    }
    get city() {
        // return this.data?.address?.location?.city
	    return (this.data?.address?.location?.notnull("city")) ? this.data.address.location.city : ""
    }
    set city(value) {
        this.controller.setState({key: "data", param: "company.address.location.city", value: value})
    }
    get state() {
        // return this.data?.address?.location?.state
	    return (this.data?.address?.location?.notnull("state")) ? this.data.address.location.state : ""
    }
    set state(value) {
        this.controller.setState({key: "data", param: "company.address.location.state", value: value})
    }
    get country() {
        // return this.data?.address?.location?.country
	    return (this.data?.address?.location?.notnull("country")) ? this.data.address.location.country : ""
    }
    set country(value) {
        this.controller.setState({key: "data", param: "company.address.location.country", value: value})
    }
    get county() {
        // return this.data?.address?.location?.county
	    return (this.data?.address?.location?.notnull("county")) ? this.data.address.location.county : ""
    }
    set county(value) {
        this.controller.setState({key: "data", param: "company.address.location.county", value: value})
    }
    get postal() {
        // return this.data?.address?.location?.postal
	    return (this.data?.address?.location?.notnull("postal")) ? this.data.address.location.postal : ""
    }
    set postal(value) {
        this.controller.setState({key: "data", param: "company.address.location.postal", value: value})
    }
    get raw_location() {
        return this.data?.address?.location
    }
    get location() {
        let location = [this.city, this.state, this.zip]
        location = (location.filter((el) => this._notnull(el) && el !== ""))?.join(", ")
        if(this._notnull(this.country)) { location += " "+this.country }
        return location
    }
    get street_address() {
        let address = (this._notnull(this.address)) ? this.address : ""
        let addrress_2 = (this._notnull(this.address_2)) ? this.address_2 : ""
        if(addrress_2 !== "") { address += " "+addrress_2 }
        return address
    }
    get full_address() {
        let address = (this.street_address !== "") ? this.street_address : ""
        if(this.location !== "") { address += ", "+this.location }
        return address
    }
    get employees() {
        return this.data?.employees
    }
    get people() {
        return this.data?.people
    }
    get tax_id() {
        return this.data?.tax_id
    }
    set tax_id(value) {
        this.data.tax_id = value
    }
    number_reduction(value) {
        if (value !== undefined && value !== null) {
            return value.replace(/[^0-9]/g, '');
        }
        return value
    }
    get_area_code(phone_number) {
        return Number((this.number_reduction(phone_number.toString())?.slice(0,3)))
    }
    get_number(phone_number) {
        return (this.number_reduction(phone_number))?.slice(3,phone_number.length)
    }
    build_phone_number(phone_number) {
        if (this.get_number(phone_number) !== undefined) {
            return {
                country_code: 1,
                area_code: this.get_area_code(phone_number),
                phone_number: this.get_number(phone_number),
                extension: 0
            }
        }
        return undefined
    }
    id(id) {
        return this.data?.companies?.[id]
    }
    index(index) {
        return this.data?.companies?.[this.data?.company_ids?.[index]]
    }
    flatten(values) {
        let flattened = []
        if (Array.isArray(values)) {
            values.map((value, index) => {
                Object.keys(value).map((k, i) => {
                    flattened.push(value[k])
                })
            })
        }
        return flattened
    }
    get summary() {
        let _summary	= {
            company: {},
            address: undefined
        }
        if (this._notnull(this.data)) {
            _summary    = JSON.parse(JSON.stringify(this.data))
            if (this.company === "") {
                _summary.company.company = {name: this.controller.getState({key: "search", param: "company.search_value"})}
            }
            if (!this._notnull(this.address) || this.address === "") {
                if (this.controller?.view?.state?.search?.address?.search_value !== undefined) {
                    _summary.address = {
                        address: this.controller.getState({key: "search", param: "address.search_value"}),
                        address_2: this.address_2,
                        location: {
                            city: this.city,
                            state: this.state,
                            zip: this.zip,
                            country: this.country
                        }
                    }
                }
            }
            _summary.trailer_types = _summary?.trailer_types
            _summary.operating_areas = _summary?.operating_areas
            if (!isNaN(_summary.number_of_trucks)) {
                _summary.number_of_trucks   = parseInt(_summary.number_of_trucks)
            } else {
                _summary.number_of_trucks   = 0
            }
            if (this.zip === "" && (_summary?.address?.location !== undefined && _summary?.address?.location !== null)) {
                    let _zip = this.controller.getState({key: "search", param: "zip.search_value"})
                //    if (!isNaN(_zip)) {
                        _summary.address.location.zip = _zip
                //    }
                }
            if (this.email !== undefined) {
                    _summary.email  = {email: this.email}
            }
            if (this.phone !== undefined) {
                    _summary.phone  = this.build_phone_number(this.phone)
            }
            if (this.fax !== undefined) {
                    _summary.fax    = this.build_phone_number(this.fax)
            }
            _summary.name = this.name
            if(typeof this.socials === "object") {
                _summary.socials = this.socials
                delete _summary.socials.id
            }
            if (_summary.name === "") { delete _summary.name }
            delete _summary.employees
            delete _summary.documents
        }
	    _summary.scrub()
        return _summary
    }
    get first() {
        return this.data?.companies?.[this.data?.company_ids?.[0]]
    }
    get all() {
        return this.data
    }
}
