// GENERAL REACT MODULES
import { Row, Col, Card, Container } from "react-bootstrap";

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx";
import "./LoadDetailsPanel.css";


export default class LoadDetailsPanel extends _basePartial {
    constructor(props) {
        super(props);
    }
    get load() {
        return this.state?.parent?.controller?.load
    }
    get total_length() {
        let _length = this.load?.total_length
        if(_length !== undefined && _length !== null) {
            return Math.round(_length / 12)
        }
    }
    render() {
        return (
            <>
                <div id={this.name}>
                    <Row className="pad_bottom">
                        <Col>
                            <Card className="details_text_color miles_panel">
                                <Card.Body>
                                    <Card.Text>
                                        <Row className="no_wrap">
                                            <Col>
                                                Miles
                                            </Col>
                                            <Col>
                                                {this.load?.distance}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                Stops
                                            </Col>
                                            <Col>
                                                {(this.load?._stops?.length !== undefined) ? this.load?._stops?.length+2 : 2}
                                            </Col>
                                        </Row>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Row className="cota_id">
                                <Col>
                                    <Row className="load_details_bold">
                                        <Col>
                                            Cota ID
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {this.load?.cota_id}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Card className="load_details_border pad_bottom panel_size">
                        <Card.Body>
                            <Card.Text>
                                <Row className="details_header">
                                    <Col className="g-0">
                                        Commodity
                                    </Col>
                                </Row>
                                <Row className="details_commodity_item">
                                    <Col className="g-0">
                                        {((this.controller._notnull(this.load?.data?.commodities?.[0]?.length)) ? "Length" : "")}
                                    </Col>   
                                    <Col className="g-0">
                                        {(this.controller._notnull(this.load?.total_length)) ? this.total_length+"ft" : "" }
                                    </Col>
                                </Row>
                                <Row className="details_commodity_item">
                                    <Col className="g-0">
                                        {((this.controller._notnull(this.load?.data?.commodities?.[0]?.weight)) ? "Weight" : "")}
                                    </Col>
                                    <Col className="g-0">
                                        {(this.controller._notnull(this.load?.total_weight)) ? this.load?.total_weight+"lbs" : "" }
                                        {/* {(this.controller._notnull(this.load?.data?.commodities?.[0]?.weight)) ? this.load?.data?.commodities?.[0]?.weight+"lbs" : "" } */}
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="load_details_border pad_bottom panel_size">
                        <Card.Body>
                            <Card.Text>
                                <Row className="details_header">
                                    <Col className="g-0">
                                        Equipment
                                    </Col>
                                </Row>
                                <Row>
                                    {console.log("THIS",this)}
                                    <Col className="details_commodity_item g-0">
                                        {this.controller.filter_equipment(this.load?.data?.equipment?.requirements, this.controller?.view?.state?.special_requirements) + " " }
                                    </Col>
                                    <Col className="details_commodity_item g-0">  
                                    {this?.state?.controller?.view?.state?.selected?.trailer_type}
                                        {/* {this.controller.filter_trailers(this.state?.parent?.controller?.load?.parent?.view?.state?.data?.shipment?.equipment?.trailer_types , this?.load?.parent?.view?.state?.parent?.state?.trailer_types) + " "} */}
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="rate_panel panel_size pad_bottom">
                        <Card.Body>
                            <Card.Text>
                                <Row className="details_header text-light">
                                    <Col className="g-0">
                                        RATE
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="g-0">
                                        <Row className="details_header_rate_price g-0">
                                            <Col>
                                                ${this.load?.calculated_rate.toFixed(0)}
                                            </Col>
                                        </Row>
                                        <Row className="details_header_rate_mile g-0">
                                            <Col>
                                                ${this.load?.rate_mile}/mile
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="load_details_border pad_bottom panel_size">
                        <Card.Body>
                            <Card.Text>
                                <Row className="details_header">
                                    <Col className="g-0">
                                        Notes
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="details_commodity_item_notes g-0">
                                        {this.load?.notes?.[0]?.note_text}
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </>
        );
    }
}
