// MODULE SPECIFIC IMPORTS
import FeedbackModelController from "controllers/ModelControllers/FeedbackModelController";
import _viewController from "controllers/ViewController/_viewController";
import feedback_info from "assets/graphql/Feedback/feedback.graphql.json"

export default class FeedbackController extends _viewController {
    constructor(props) {
        super(props)
        this.feedback = new FeedbackModelController({params: { parent: this,
            controller: this,
            is_view: true}})
    }
save() {
  console.log(this.context.self.id)
        this.feedback.summary.user_id = this.context.self.id
        console.log(this)
        let summary = JSON.parse(JSON.stringify(this.feedback?.summary))
        let body = JSON.parse(JSON.stringify(feedback_info))
        let data = this.toUnquotedJSON(summary)
        // let data = this.controller.toUnquotedJSON(_summary)
        body.query = body.query.replace("input:", 'input: '+data+'')
        console.log("QUERY",body.query)
        this.api.feedback.ask({caller: this, params: {body: body}, callback: {f: this.process_feedback}})
    
}
async process_feedback({caller, params, results}) {
    console.log("RESULTS",results)

    console.log("CALLER",caller)
    if(results?.errors !== undefined) {

        console.log("Error creating event", results.errors)
        return
    }

    // console.log("Event created")
    if(results?.data?.feedback?.id !== undefined && results?.data?.feedback?.id  !== null) {
        caller.feedback._id = results.data.feedback.id
        if (caller?.uploader !== undefined) {
            console.log("ABOUT TO CALL")
            await caller.uploader?.process_queue(results?.data?.feedback?.id )
        }
    }
}
}