// GENERAL REACT MODULES
import _basePartial from "components/General/_basePartial.jsx"
import { Row, Col, Dropdown } from 'react-bootstrap'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

// ALL OTHER MODULES
import "./ListNav.css"
import Controller from "./ListNav.controller.jsx"
import { RiRefreshFill } from "react-icons/ri";


export default class ListNav extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: {view: this}})
        this.state.page_length  = this?.main_context?.self?.settings?.row_length ?? 20
        this.state.page         = 1
        this.state.max_pages    = 1
        this.state.rows         = 1
        this.state.max_rows     = 1
    }
    get line_height() {
        return 40
    }
    render_refresh() {
        if (!this.state.is_bottom) {
            return (
                <Col sm={1} className="end_buffer font_style" onClick={this.partial_controller?.refresh_results.bind(this)}>
                    <div className="refresh_btn_wrapper">
                        <RiRefreshFill size={20}/>
                    </div>
                </Col>
            )
        } else {
            return (
                <Col sm={1} className="end_buffer"/>
            )
        }
    }
    render() {
        return (
            <Row id={this.name} style={{height: "44px", "lineHeight": this.line_height+"px"}} className={"g-0 "+((this.state.is_bottom) ? "btm_top" : "")}>
                <Col>
                
                </Col>
                <Col sm={1} className="rows_per_page font_style rows_col">
                    Rows per Page:
                </Col>
                <Col sm={1} className="arrow_btn font_style">
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {this.controller?.view?.state.page_length}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={(e) => { this.partial_controller.selected(5) } }>5</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { this.partial_controller.selected(10) } }>10</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { this.partial_controller.selected(15) } }>15</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { this.partial_controller.selected(20) } }>20</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { this.partial_controller.selected(25) } }>25</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { this.partial_controller.selected(50) } }>50</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col sm={1} className="rows_per_page font_style">
                    Page: {this.controller.getState({key: "page"})}
                </Col>
                <Col sm={1} className="rows_per_page font_style">
                    {((this.controller.getState({key: "page"})-1) * this.controller.getState({key: "page_length"}))+1} - {this.controller.getState({key: "page"})*this.controller.getState({key: "page_length"})} of {this.state.max_pages}
                </Col>
                <Col sm={1} className="arrow_btn font_style">
                    <div className="arrow_btn_wrapper arrow_btn_wrapper_left" onClick={this.partial_controller.nav.bind(this, false)}>
                        <MdKeyboardArrowLeft size={20}/>
                    </div>
                </Col>
                <Col sm={1} className="arrow_btn font_style">
                    <div className="arrow_btn_wrapper arrow_btn_wrapper_right" onClick={this.partial_controller.nav.bind(this, true)}>
                        <MdKeyboardArrowRight size={20}/>
                    </div>
                </Col>
                {this.render_refresh()}
            </Row>
        )
    }
}
