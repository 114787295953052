// GENERAL REACT MODULES
import React from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker"
import TimePicker from 'react-time-picker';
import "react-datepicker/dist/react-datepicker.css";
import { AiFillCloseCircle } from "react-icons/ai";

// ALL OTHER MODULES
import _baseComponent from "./_baseComponent.jsx";
import { MultiSelectDropdown, SelectDropdown } from "components/Dropdowns/Dropdowns.jsx"

export default class _baseForm extends _baseComponent {
    constructor(props) {
        super(props)
        this.state.search       = {}
        this.searchElement      = {}
        this.modal              = {}
        this._toggled_modal     = false
        this._draggable_modal   = true
        this._center_modal      = true
        if (props?.params?.data === undefined) { this.state.data = {} }
    }
    searchable({ event, controller, data}) {
        controller.setState({key: data?.title, param: data?.name+".selected_value", value: undefined})
      //  console.log("CONTROLLER :",controller)
        if (this.state[event.target?.title] === undefined) {
            this.state[event.target?.title] = {}
        }
        this.state[event.target?.title][event.target?.name] = {search_value: event.target?.value, return_value: undefined}
        controller?.perform_search({title: event.target?.title, 
                                            name: event.target?.name, 
                                            data: data,
                                            values: data?.values,
                                            value: event.target?.value})
    }
    close_searchable(data, event, controller) {
        controller.setState({key: data?.title, param: data?.name+".selected_value", value: undefined})
        this.get_search_value({data: data, controller: controller})
        // controller.setState({key: data?.title, param: data?.name, value: event.target?.value})
        // this.controller?.follow_on_empty({data: data})
        //this.forceUpdate()
    }
    empty_results(data, controller) {
        controller = (controller !== undefined) ? controller : this.controller
        controller.setState({key: data?.title, param: data?.name, value: {search_value: ""}})
        controller?.follow_on_empty({data: data})
        //this.forceUpdate()
    }
    class_searchable_input({data, name, title}) {
        if (this.state?.[title]?.[name]?.selected_value !== undefined) {
            return "full_border"
        }
        if (this.state?.[title]?.[name]?.return_value?.length > 0 && data?.selection !== false) {
            return "searchable_border"
        }
        return "full_border"
    }
    class_can_empty({name, title}) {
        if (this.state?.[title]?.[name]?.selected_value !== undefined && this.state?.[title]?.[name]?.selected_value !== null) {
            if (Object.keys(this.state?.[title]?.[name]?.selected_value).length > 0) {
                return ""
            }
        }
        return "hide"
    }
    class_searchable_results({data, name, title}) {
        // if (data?.selection === false) {
        if (this.state?.[title]?.[name]?.selected_value !== undefined || this.searchElement[name]?.id !== document.activeElement.id || data?.selection === false) {
        // if (this.state?.[title]?.[name]?.selected_value !== undefined) {
            return "hide"
        }
        if (this.state?.[title]?.[name]?.return_value?.length > 0) {
            return ""
        }
        return "hide"
    }
    async follow_on_selection({event, obj, data}) { }
    selection_action({event, obj, owner, data, controller}) {
        let cobj = JSON.parse(JSON.stringify(obj))
        if (this?.state?.[data?.title]?.[data?.name] !== undefined) {
            if (owner?.state?.only_form) {
                owner.follow_on_selection({event: event, obj: cobj, data: data})
            } else {
                controller?.setState({key: data?.title, param: data?.name+".selected_value", value: cobj})
                controller?.follow_on_selection({event: event, obj: cobj, data: data})
                this.forceUpdate()
            }
        }
    }
    get_search_value({data, controller}) {
        let selected = controller?.getState({key: data?.title, param: data?.name})
        if (selected?.selected_value === undefined || selected?.selected_value == null || Object.keys(selected?.selected_value).length == 0) {
            return selected?.search_value
        }
        return selected?.selected_value?.[data?.subtype]
    }
    map_results({data, owner, controller}) {
        let results = this.state?.[data?.title]?.[data?.name]?.return_value
        if (data?.selection === false) {
            return (<></>)
        }
        if (this.state?.[data?.title]?.[data?.name]?.selected_value === undefined) {
            if (results !== undefined) {
                if (Array.isArray(results)) {
                    return (
                        results.map((obj, index) => {
                            return <div className={"search_results_row "+this.results_bottom({results: results, index: index})} 
                                        key={"search_results_"+index}
                                        onClick={(e) => this.selection_action({event: e, obj: obj, owner: owner, data: data, controller: controller})}>
                                            {this.generate_label({obj: obj, labels: data.label, sub_labels: data.sub_labels, limit: data.limit})}
                                    </div>
                        })
                    )
                }
            }
        }
    }
    generate_label({obj, labels, sub_labels, limit}) {
        let return_label = obj?.[labels]
        if (Array.isArray(sub_labels)) {
            sub_labels.map((sub_label, index) => {
                if (typeof sub_label === "string") {
                    return_label = return_label+" [ "+obj?.[sub_label]+" ] "
                } else {
                    Object.keys(sub_label).map((key, index) => {
                        let keys = sub_label[key]
                        if (Array.isArray(keys)) {
                            if (keys.length > 0) {
                                return_label = return_label+" ["
                                keys.map((k, index) => {
                                    return_label = return_label+" "+obj?.[key]?.[k]+" "
                                })
                                return_label = return_label+" ]"
                            }
                        }
                    })
                }
            })
        }
        return return_label 
    }
    trim_label({label, limit}) {
        return label.length > limit ? 
                    label.substring(0, limit - 3) + "..." : 
                    label;
    }
    results_bottom({results, index}) {
        if (index < results.length-1) {
            return "search_results_row_bottom_border"
        }
        return "search_results_row_bottom"
    }
    get_value(data) {
        if (this.state?.[data?.title]?.[data?.name]?.selected_value?.name !== undefined) {
                return this.state?.[data?.title]?.[data?.name]?.selected_value?.name
        }
        return undefined
    }
    wrapEvent({data, owner, event, controller}) {
        let message = { 
            target: data
        }
        message.target.value = event
        this.wrap_controller({data: data, owner: owner, event: message, controller: controller})
    }
    wrap_controller({data, owner, event, controller, index}) {
	    let obj_index = (index !== undefined) ? index : owner?.state?.index
        controller = (controller === undefined) ? this.controller : controller
        if (owner?.modal_controller === undefined) {
            controller?.updateValue({ message: event, index: obj_index})
        } else {
            owner.modal_controller?.updateValue({ message: event, index: obj_index})
        }
        this.forceUpdate()
    }
    formObject({data, owner, controller, options, wrapped_controller, disabled, index}) {
        disabled = (disabled === undefined) ? data?.disabled : disabled
        let menuShouldScrollIntoView = (data?.menuShouldScrollIntoView === "false" ? false : true)
        let selectedOptionsWillDisplay = (data?.selectedOptionsWillDisplay === "false" ? false : true)
        let today = new Date()
        let lookup_attribute = data?.name
        if (Array.isArray(data?.name)) {
            lookup_attribute = data?.name[0]
        }
        controller = (controller !== undefined) ? controller : this.controller
        wrapped_controller = (wrapped_controller !== undefined) ? wrapped_controller : controller
        let value = controller?.[data?.title]?.[data?.name]
        if (index !== undefined) {
            // index = 0
            value = controller?.[data?.title]?.[index]?.[data?.name]
        }

        switch (data?.type) {
            case "timepicker": 
                return (
                    <TimePicker tabIndex={this.tab(data?.name)}
                                disableClock
                                name={this.validStr(data?.name)}
                                title={this.validStr(data?.title)}
                                disabled={(disabled) ? disabled : false}
                                onChange={(event) => this.wrapEvent({data: data, owner: owner, event: event, controller: wrapped_controller})}
                                value={(controller?.[data?.title]?.[data?.name] !== undefined) ? controller?.[data?.title]?.[data?.name] : '00:00'}
                                className={"full_border form-control "+data?.classes+" "+this._theme}
                                onFocus={() => this.setState({key: "active_element"})}
                                id={this.validStr(data?.name+"-"+data?.id)}/>
                )
            case "datepicker":
                return (
                    <DatePicker tabIndex={this.tab(data?.name)}
                                dateFormat="yyyy-MM-dd"
                                minDate={today}
                                name={this.validStr(data?.name)}
                                title={this.validStr(data?.title)}
                                disabled={(disabled) ? disabled : false}
                                // selected={controller[data?.title]?.[data?.name]}
                                selected={((controller?.[data?.title]?.[data?.name] !== undefined) ? Date.parse(controller?.[data?.title]?.[data?.name]) : undefined)}
                                onChange={(event) => this.wrapEvent({data: data, event: event, controller: wrapped_controller})}
                                className={"full_border form-control "+data?.classes+" "+this._theme}
                                onFocus={() => this.setState({key: "active_element"})}
                                id={this.validStr(data?.name+"-"+data?.id)}/>
                )
            case "multi":
                return (
                    <MultiSelectDropdown params={{parent: owner,
                                                    name: this.validStr(data?.name),
                                                    title: this.validStr(data?.title),
                                                    controller: controller,
                                                    index: index,
                                                    styled: data?.styled,
                                                    options: options,
                                                    disabled: ((disabled) ? disabled : false),
                                                    optionSelected: ((controller?.[data?.title]?.[data?.name] !== undefined) ? controller?.[data?.title]?.[data?.name] : 
                                                                        (owner?.state?.[data?.title]?.[data?.name] !== undefined) ? owner?.state?.[data?.title]?.[data?.name] : undefined),
                                                    className: data?.classes,
                                                    root: data?.root,
                                                    id: this.validStr(data?.name+"-"+((data?.id !== undefined ) ? data?.id : index)),
                                                    menuShouldScrollIntoView: menuShouldScrollIntoView, 
                                                    selectedOptionsWillDisplay: selectedOptionsWillDisplay
                                                    }}/>
                )
            case "select":
                return (
                    <SelectDropdown params={{parent: owner,
                                                name: this.validStr(data?.name),
                                                title: this.validStr(data?.title),
                                                controller: controller,
                                                index: index,
                                                options: options,
                                                disabled: ((disabled) ? disabled : false),
                                                // value: ((controller[data?.title]?.[data?.name] !== undefined) ? controller[data?.title]?.[data?.name] : ""),
                                                // optionSelected: ((controller[data?.title]?.[data?.name] !== undefined) ? controller[data?.title]?.[data?.name] : undefined),
                                                className: data?.classes,
                                                root: data?.root,
                                                id: this.validStr(data?.name+"-"+data?.id),
                                                menuShouldScrollIntoView: menuShouldScrollIntoView 
                                            }}/>
                )
            case "checkbox":
                return (
                    <Form.Check tabIndex={this.tab(data?.name)}
                                    type={"checkbox"}
                                    checked={(index === undefined) ? controller?.[data?.title]?.[data?.name] : controller?.[data?.title]?.[index]?.[data?.name]}
                                    name={this.validStr(data?.name)}
                                    title={this.validStr(data?.title)}
                                    placeholder={this.validStr(data?.title)}
                                    className={this.validStr(data?.classes)}
                                    label={this.validStr(data?.label)}
                                    disabled={(disabled) ? disabled : false}
                                    onFocus={() => this.setState({key: "active_element"})}
                                    // onChange={event => this.controller?.updateValue({ message: event, index: owner?.state?.index})}
                                    onChange={(event) => this.wrap_controller({event: event, owner: owner, controller: wrapped_controller})}
                                    id={this.validStr(data?.name+"-"+data?.id)}/>

                )

            case "searchable":
                let search_value = this.get_search_value({data: data, controller: controller})
                if (typeof search_value !== "string") {
                    search_value = ""
                }
                return (
                    <div className="relative">
                        <Form.Control tabIndex={this.tab(data?.name)}
                                    ref={ (parentElement) => { this.searchElement[data?.name] = parentElement } }
                                    type={this.validStr(this.validStr(data?.type))}
                                    placeholder={this.validStr(data?.placeholder)}
                                    defaultValue={this.controller?.get_object_param({key: data?.title, param: data?.name})}
                                    name={this.validStr(data?.name)}
                                    value={search_value}
                                    onFocus={() => this.setState({key: "active_element"})}
                                    onChange={(event) => this.searchable({event: event, controller: controller, data: data})}
                                    title={this.validStr(data?.title)}
                                    className={this.class_searchable_input({data: data, name: data?.name, title: data?.title})+" "+this._theme+" "+this.validStr(data?.classes)+" "+((data?.readOnly !== undefined) ? "no_edit" : "")}
                                    size={this.validStr(data?.size)}
                                    readOnly={(data?.readOnly !== undefined) ? data?.readOnly : false}
                                    step={data?.step}
                                    disabled={(disabled) ? disabled : false}
                                    autoComplete="off"
                                    id={this.validStr(data?.name+"-"+data?.id)}/>
                        {this.enable_searchable({data:data, owner: owner, disabled: disabled, controller:controller, element: this.searchElement[data?.name]})}
                    </div>
                )
            case "number":
                return (
                    <Form.Control tabIndex={this.tab(data?.name)}
                                        type={this.validStr(this.validStr(data?.type))}
                                        placeholder={this.validStr(data?.placeholder)}
                                        name={this.validStr(data?.name)}
                                        value={this.validate_number((index === undefined) ? controller?.[data?.title]?.[data?.name] : controller?.[data?.title]?.[index]?.[data?.name])}
                                        onChange={(e) => this.wrap_controller({event: e, owner: owner,controller: controller, indx: index})}
                                        title={this.validStr(data?.title)}
                                        className={"full_border "+this._theme+" "+this.validStr(data?.classes)+" "+((data?.readOnly !== undefined) ? "no_edit" : "")}
                                        size={this.validStr(data?.size)}
                                        disabled={(disabled) ? disabled : false}
                                        readOnly={(data?.readOnly !== undefined) ? data?.readOnly : false}
                                        onFocus={() => this.setState({key: "active_element"})}
                                        autoComplete="off"
                                        id={this.validStr(data?.name+"-"+data?.title)}/>
                )
            default:
                return (
                    <Form.Control tabIndex={this.tab(data?.name)}
                                    type={this.validStr(this.validStr(data?.type))}
                                    placeholder={this.validStr(data?.placeholder)}
                                    as={data?.textarea ? "textarea" : "input"}
                                    value={(index === undefined) ? controller?.[data?.title]?.[data?.name] : controller?.[data?.title]?.[index]?.[data?.name]}
                                    name={this.validStr(data?.name)}
                                    onChange={(event) => this.wrap_controller({event: event, owner: owner, controller: wrapped_controller, index: index})}
                                    title={this.validStr(data?.title)}
                                    className={"full_border "+this._theme+" "+this.validStr(data?.classes)+" "+((data?.readOnly !== undefined) ? "no_edit" : "")}
                                    size={this.validStr(data?.size)}
                                    disabled={(disabled) ? disabled : false}
                                    readOnly={(data?.readOnly !== undefined) ? data?.readOnly : false}
                                    onFocus={() => this.setState({key: "active_element"})}
                                    autoComplete="off"
                                    id={this.validStr(data?.name+"-"+data?.title)}/>
                )
        }
    }
    validate_number(input) {
        if (isNaN(input)) {
            return undefined
        }
        return input
    }
    enable_searchable({data, owner, disabled, controller}) {
        if (!disabled) {
            return (
                <>
                    <AiFillCloseCircle size={20} className={"searchable_close "+this.class_can_empty({name: data?.name, title: data?.title})} onClick={this.empty_results.bind(this, data, controller)}/>
                    <div className={"searchable_menu "+this.class_searchable_results({data: data, name: data?.name, title: data?.title}) }>
                        {
                            this.map_results({data: data, owner: owner, controller: controller})
                        }
                    </div>
                </>
            )
        }
    }
    validStr(message) {
        if (typeof message === 'string' && message.length !== 0) {
            return message
        }
        if (message === undefined || message === null) {
                return ""
        }
        return message.toString()
    }
}
