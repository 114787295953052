
// GENERAL REACT MODULES
import { Row, Col, Card } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import "./StatusPanel.css"

export default class StatusPanel extends _basePartial {
    constructor(props) {
        super(props)
    }
    render_appointment({details}) {
        if (details?.appointment_required) {
            return(
                <Col className="align_right text_style">
                    *Appointment
                </Col>
            )
        }
    }
    render_time({time}) {
        if (time !== undefined && time !== null) {
            return time
        }
        return ""
    }
    render_times({start, stop}) {
        let rendered_times = ""
        if (start !== undefined) {
            rendered_times = this.render_time({time: start}) + ((stop !== undefined) ? " - " : "")
        }
        if (stop !== undefined) {
            rendered_times = rendered_times + this.render_time({time: stop})
        }
        return rendered_times
    }
    render_stop({index, details}) {
        return(
            <Card className="border_color_middle details_style text_style" key={"details_"+index}>
                <Card.Body>
                    <Card.Text>
                        <Row className="no_wrap g-0">
                            <Col className="center_text">
                                {((details?.location?.stop_type !== undefined && details?.location?.stop_type !== "undefined") ? details?.location?.stop_type : "Stop")}
                            </Col>
                            <Col className="align_right"> 
                                {details?.location?.date}  {this.render_times({start: details?.start_time, stop: details?.end_time})}
                            </Col>
                        </Row>
                        <Row className="g-0">
                            {
                                this.render_appointment({details: details})
                            }
                        </Row>
                        <Row className="loc_row g-0">
                            <Col>
                                <Row className="g-0" style={{display: "none"}}>
                                    <Col>
                                    {   ((details?.location?.name !== undefined && details?.location?.name !== "undefined" && details?.location?.name !== "null") ? details?.location?.name : "")}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="g-0">
                                        {((details?.location?.city !== undefined && details?.location?.city !== "undefined" && details?.location?.city !== "null") ? details?.location?.city : "")}
                                        {((details?.location?.city !== undefined && details?.location?.city !== "") ? "," : " ")}
                                    </Col>
                                    <Col className="g-0 loc_state">
                                    {((details?.location?.state !== undefined && details?.location?.state !== "undefined" && details?.location?.state !== "null") ? details?.location?.state : "")}
                                    </Col>
                                    <Col className="g-0 loc_zip">
                                    {((details?.location?.zip !== undefined && details?.location?.zip !== "undefined" && details?.location?.zip !== "null") ? details?.location?.zip : "")}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Text>
                </Card.Body>
            </Card>
        )
    }
    get load() {
        return this.state?.parent?.controller?.load
    }
    get pickup() {
        return this.state?.parent?.controller?.pickup
    }
    get dropoff() {
        return this.state?.parent?.controller?.consignee
    }
    render() {
        console.log(this)
        console.log(this.load)
        return (
            <>
                <div id={this.name}>
                    <div className="">
                        {
                            this.render_stop({details: {location:{  city: `${this.pickup?.contact?.city}`,
                                                                    state:`${this.pickup?.contact?.state}`,
                                                                    zip:`${this.pickup?.contact?.zip}`,
                                                                    stop_type: `${this.pickup?.type}`,
                                                                    date: this.pickup?.rendered_date
                                                                }}})
                        }
                    </div>
                    {
                        this.controller?._stops?.map((stop, index) => {
                            return this.render_stop({details: stop, index: index})
                        })
                    }
                    <div>
                        {
                            this.render_stop({details: {location:{  city: `${this.dropoff?.contact?.city}`,
                                                                    state:`${this.dropoff?.contact?.state}`,
                                                                    zip:`${this.dropoff?.contact?.zip}`,
                                                                    stop_type: `${this.dropoff?.type}`,
                                                                    date: this.dropoff?.rendered_date
                                                                }}})
                        }
                    </div>
                </div>

            </>
        )
    }

}
