import React from "react";
import _basePanelView from "components/General/_basePanelView.jsx";
import Controller from "./JotForm.controller";
// import "./JotForm.css"

import {
  Container,
  Row,
  Col,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";

export default class JotForm extends _basePanelView {
  constructor(props) {
    super(props);
    this.controller         = new Controller({params: {view: this}})
    this.handleProcessEvent = this.handleProcessEvent.bind(this)
  }
  renderView() {
    return (
      <>
        <Container className="generic_100 g-0" >
        </Container>
      </>
    );
  }
}
