// GENERAL REACT MODULES

// ALL OTHER MODULES
import _ModelController from "./_ModelController";
import CompanyModelController from "./CompanyModelController";

export default class UserModelController extends _ModelController {
    constructor(props) {
        super(props)
        this.company = new CompanyModelController({ params: { parent: this, controller: this.controller } })
        let get_active_role = this.get_active_role.bind(this)
        let get_first_name = this.get_first_name.bind(this)
        let get_last_name = this.get_last_name.bind(this)
        this.role = {
            get active() {
                return get_active_role()
            },
            get a() {
                return get_active_role()
            },
            get first() {
                return get_active_role((get_active_role())?.[0])
            }
        }
        this.name = {
            get full() {
                return get_first_name() + " " + get_last_name()
            },
            get first() {
                return get_first_name()
            },
            get last() {
                return get_last_name()
            }
        }
    }
    get_company_ids(index) {
        if (index === undefined) {
            return this.data.company_ids
        }
        return this.companies?.[index]
    }
    get_role_ids(index) {
        if (index === undefined) {
            return this.data.role_ids
        }
        return this.roles?.[index]
    }
    get_active_role() {
        if (this.data?.roles !== undefined) {
            return this.data?.roles[Object.keys(this.data?.roles).filter((key) => this.data?.roles[key].status === "ACTIVE" || this.data?.roles[key].status === "ASSIGNED")]
        }
        return undefined
    }
    get_first_name() {
        if (this._notnull(this.data?.given_name)) {
            return this.data.given_name
        }
        if (this._notnull(this.data?.first_name)) {
            return this.data.first_name
        }
    }
    get family_name() {
        if (this._notnull(this.data?.family_name)) {
            return this.data.family_name
        }
        if (this._notnull(this.data?.last_name)) {
            return this.data.last_name
        }
        return ""
    }
    set family_name(value) {
        this.data.family_name = value
    }
    get_last_name() {
        if (this._notnull(this.data?.family_name)) {
            return this.data.family_name
        }
        if (this._notnull(this.data?.last_name)) {
            return this.data.last_name
        }
    }
    get given_name() {
        if (this._notnull(this.data?.given_name)) {
            return this.data.given_name
        }
        if (this._notnull(this.data?.first_name)) {
            return this.data.first_name
        }
        return ""
    }
    set given_name(value) {
        this.data.given_name = value
    }
    get preferences() {
        return this.data?.preferences
    }
    get phone_number() {
        if (this._notnull(this.data?.phone_number)) {
            return this.data.phone_number
        }
        if (this._notnull(this.data?.phone)) {
            return this.data.phone
        }
        return ""
    }
    set phone_number(value) {
        this.data.phone_number = value
    }
    get email() {
        return this.data?.email
    }
    set email(value) {
        this.data.email = value
    }
    get roles() {
        return this.data?.roles
    }
    get role_status() {
        if (this._notnull(this.roles?.[0]?.status)) {
            return this.roles?.[0]?.status
        }
        return "Pending"
    }
    set role_status(value) {
        this.roles[0].status = value
    }
    get assigned_role() {
        return this.roles?.[0]?.label
    }
    set assigned_role(value) {
        this.roles[0] = this.format_role(value)
    }
    get companies() {
        return this.data?.companies
    }
    get socials() {
        return this.data?.socials
    }
    get facebook() {
        return this.socials?.facebook
    }
    set facebook(value) {
        this.data.socials.facebook = value
    }
    get menu_open() {
        return this.data?.settings?.menu_open
    }
    set menu_open(value) {
        this.data.settings.menu_open = value
        // this.setState({key: "menuCollapse", param: undefined, value: this.data?.settings?.menu_open})
        // this.data.settings.menu_open = value
    }

    get row_length() {
        return this.data?.settings?.row_length
    }
    set row_length(value) {
        this.data.settings.row_length = value
        // this.setState({key: "menuCollapse", param: undefined, value: this.data?.settings?.row_length})
        // this.data.settings.menu_open = value
    }

    get instagram() {
        return this.socials?.instagram
    }
    set instagram(value) {
        this.data.socials.instagram = value
    }
    get linkedin() {
        return this.socials?.linkedin
    }
    set linkedin(value) {
        this.data.socials.linkedin = value
    }
    get twitter() {
        return this.socials?.twitter
    }
    set twitter(value) {
        this.data.socials.twitter = value
    }
    get settings() {
        return this.data?.settings
    }
    get measurement_system() {
        return this.settings?.measurement_system
    }
    set measurement_system(value) {
        return this.data.settings.measurement_system = value
    }
    // get data() {
    //     return this.controller.getState({key: "context"})?.get_self()
    // }
    get data() {
        return (this._state?.data !== undefined) ? this._state?.data : this.controller.getState({ key: this._data_key, param: "user" })
    }
    format_role(role) {
        let obj = { label: role }
        switch (role.toUpperCase()) {
            case "ADMINISTRATOR":
                obj.code = 1; break
            case "DRIVER":
                obj.code = 5; break
            case "OWNER":
                obj.code = 4; break
            case "MANAGER":
                obj.code = 2; break
            case "VIEWER":
                obj.code = 10; break
            default:
                obj.code = 10; break
        }
        return obj
    }
    generate_roles() {
        let _roles = []
        if (this._notnull(this.roles)) {
            this.roles.map((el, index) => _roles.push({ code: el?.code, status: el?.status }))
        }
        return _roles
    }
    number_reduction(value) {
        if (value !== undefined && value !== null) {
            return value.replace(/[^0-9]/g, '');
        }
        return value
    }
    get_area_code(phone_number) {
        return Number((this.number_reduction(phone_number)?.slice(0, 3)))
    }
    get_number(phone_number) {
        return (this.number_reduction(phone_number))?.slice(3, phone_number.length)
    }
    build_phone_number(phone_number) {
        if (this.get_number(phone_number) !== undefined) {
            return {
                country_code: 1,
                area_code: this.get_area_code(phone_number),
                phone_number: this.get_number(phone_number),
                extension: 0
            }
        }
        return undefined
    }
    get summary() {
        let _summary = {}
        _summary.id = this._id
        _summary.family_name = this.family_name
        _summary.given_name = this.given_name
        _summary.name = this.name.full
        if (this._notnull(this.phone_number)) {
            _summary.phone_number = this.build_phone_number(this.phone_number)
        }
        if (this._notnull(this.email)) {
            _summary.email = { email: this.email }
        }
        if (this._notnull(this.settings)) {
            _summary.settings = this.settings
            _summary.settings.language = this.settings?.language?.code
        }
        if (this._notnull(this.socials)) {
            _summary.socials = this.socials
        }
        _summary.roles = this.generate_roles()
        _summary.scrub()
        return _summary
    }
}
