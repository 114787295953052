// GENERAL REACT MODULES
// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import document_types from "assets/graphql/Document_types/document_types.graphql.json"
import self_request from "assets/graphql/Users/self.graphql.json"

export default class UploadPartialController extends _viewController {
    constructor(props) {
        super(props)
        this.isMounted  = true
        this.requests   = [{request: document_types, 
                                    callback: {p: {name: "document_types", var: "document_types", disp: "label"}}, 
                                    endpoint: "document_types"},
                            {request: self_request,
                                    callback: {f: this.load_user, p: {name: "self"}}, endpoint: "users"}]
    }
    check_save() {
       // console.log("CHECKING",this.view.props.params)
        if(this?.view?.props?.params?.uploaded) {
            return
        }
        if(this.view.props.params.save && this?.view?.props?.params?.new_image) {
          //  console.log("LOOKING FOR STATE",this)
            // this.controller.setState({key: "preview", value: false})
            this.controller.setState({key: "uploaded", value: true})
            let file = this.controller.getState({key: "current_image"})
            let body = {
                file: file,
                private: false,
                endpoint: this?.view?.props?.params?.endpoint,
                id: this?.view?.props?.params?.id,
                is_profile_pic: true,
            }
            if(this?.view?.props?.params?.existing_image){
                this.controller.api.upload.file_delete({
                    caller: this, 
                    value: this?.view?.props?.params?.existing_image,
                    id: this?.view?.props?.params?.existing_image?.id
                })
                body.delete = true
                this.upload(body)
            } else {
                let body = {
                    file: file,
                    private: false,
                    endpoint: this?.view?.props?.params?.endpoint,
                    id: this?.view?.props?.params?.id,
                    is_profile_pic: true
                }
                this.upload(body)
            }
        }
    }
    load_user({caller, params, results}) {
        if(results?.errors === undefined && results?.data?.self !== undefined) {
            caller.view.state.data.user = caller.getCopy(results.data.self)
            caller.view.state.cache = caller.getCopy(caller.view.state.data.user)
            caller.view.forceUpdate()
        }
    }
    upload_user(event) {
        this.partial_controller.browse_user(event)
        this.partial_controller.upload()
    }

 

    upload_contact(event){
        let value = URL.createObjectURL(event.target.files[0])
        this.state.url = value
        this.controller.view.forceUpdate()
        this.state.queue.push(event.target.files[0])
    }
    browse_user(event){
      //  console.log("SETTING CURRENT_FILE",this)
        this.controller.setState({key: "current_image", value: event.target.files[0]})
        this.controller.setState({key: "preview", value: true})
      //  console.log("SETTING CURRENT_FILE 2",event.target.files[0])
        this.controller.view.forceUpdate()
    }
    browse(event) {
        this.controller.setState({key: "current_file", value: event.target.files[0]})
        this.state.url = this.controller.getState({key: "current_file"})
        this.forceUpdate()
    }
    async process_queue(id) {
        console.log("PROCESS QUEUE",this,id)
        if(this.view.state.endpoint === "contact" || "carrier" || "event" || "feedback" ){
            let file = this.controller.getState({key: "current_file"})
            let body = {
                file: file,
                private: this.view.state.endpoint === "event" ? true : false,
                endpoint: this.view.state.endpoint,
                id: id
            }
            if(this.view.state.endpoint === "event" && file.name === undefined )
            {return}
            if(this.view.state.endpoint === "feedback" && file.name === undefined )
            {return}
            this.upload(body)
        }
        if (id !== undefined) {
            for (const queued of this.view.state.queue) {
        //    this.view.state.queue.map((queued, index) => {
                queued.id = id
                await this.upload(queued)
            }
        }
        return {}
    }
    async upload(queued) {
       console.log("WE ARE UPLOADING",this)
        let delete_file = this.controller.getState({key: "delete"})
       // console.log("VALUE",delete_file)
        let endpoint = this.getState({key: "endpoint"})
        if(endpoint === "loads"){
            endpoint = "load"
        }
        if(endpoint === "event"){
            this.controller[endpoint]._id = queued?.id
        }
        if (this.controller?.[endpoint]?._id !== undefined && this.controller?.[endpoint]?._id !== null) {
            let file = this.controller.getState({key: "current_file"})
            let ext = ".png"
            if(file.name){
                console.log("FILE NAME",file)
             ext = file?.name.split('.').pop()
            }
            let custom_name = undefined
            if(this?.controller?.[endpoint]?.document_name !== undefined && this?.controller?.[endpoint]?.document_name !== "undefined"){
             custom_name = this?.controller?.[endpoint]?.document_name + `.${ext}`
            }
         //   console.log("CHECKING THE EXT",ext)
          console.log("CUSTOM NAME",custom_name)
            if(custom_name){
                custom_name =   this.resovle_file_name(custom_name, this?.controller?.[endpoint]?.documents)
               }else{
                   custom_name = this.resovle_file_name(file?.name)
               }
               console.log("FILE",file)
            if(file === undefined) { return }
            let body = {}
            if (queued === undefined) {
                body = {
                    file: file,
                    doc_type: this.getState({key: "document", param: "type"}),
                    private: this.controller.view.state?.private_file,
                    endpoint:  endpoint,
                    id: this.controller?.[endpoint]?._id,
                    document_name: custom_name
                }
            } else {
                if(custom_name){
                    queued.document_name = custom_name
                }
                body = queued
            }


            if (body?.id !== undefined && body?.id !== null) {
               console.log("BODY",body)

                await this.controller.api.upload.file_upload({caller: this,
                                                        body: body,
                                                        callback: {
                                                            f: this.upload_callback,
                                                            p: {endpoint: body.endpoint, endpoint_id: this.controller?.[endpoint]?._id}}})
            }
             else {
             //  console.log(body)
                if (queued === undefined) {
                    this.view.state.queue.push(body)
                }
            }
            this.view.toggle_modal({toggle_state: false})
            this.handle_reset()
            this.controller.view.forceUpdate()
            return {}
        }
        this.view.toggle_modal({toggle_state: false})
    }
    async file_download(){
        let endpoint = this.getState({key: "endpoint"})
        let file = this.controller.getState({key: "current_file"})
        let body = {
            file: file,
            doc_type: this.getState({key: "document", param: "type"}),
            private: false,
            endpoint: endpoint,
            id: this.controller?.[endpoint]?._id
        }
        this.controller.api.upload.file_download({caller: this,
            body: body,
            callback: {
                f: this.upload_callback,
                p: {endpoint: body.endpoint}}})
    }
    upload_callback({results, caller, params}) {
        if(typeof caller?.controller?.upload_callback === "function") {
            caller?.controller?.upload_callback({caller: caller.controller, params: params, results: results})
        } 
        // CAUSES AN INFINTE LOOP

        //  else {
        //     if (typeof caller?.upload_callback === "function") {
        //         console.log("ELSE")

        // else {
        //     if (typeof caller?.upload_callback === "function") {

        //         caller?.upload_callback({caller: caller, params: params, results: results}) 
        //     }
        // }
    }
    update_file(event, file, filenum) {
    //    console.log("UPDATING FILE")
        let endpoint = this.getState({key: "endpoint"})
        let id = this.controller?.[endpoint]?._id
            if (id !== undefined) {
            let body = {
                file: event.target.files[0],
                docType: this.getState({key: "document", param: "type"}),
                private: this.controller.view.state?.private_file, 
                endpoint: endpoint,
                id: this.controller?.[endpoint]?._id
            }
            file.endpoint = body.endpoint
            file.resource_id = file.id
            file.id = body.id
            this.remove_file({filenum: filenum})
            this.controller.api.upload.file_update({caller: this, 
                                                    value: file, 
                                                    body: body,
                                                    endpoint: body.endpoint, 
                                                    callback: {
                                                        f: this.update_callback, 
                                                        p: {endpoint: body.endpoint}}})
            this.handle_reset()
            this.controller.view.forceUpdate()
        }
    }
    update_callback({results, caller, params}) {
        let id = caller.endpoint_id
        if (id !== undefined) {
            caller.api.upload.fetch_files({caller: caller, endpoint: params.endpoint, id: id})
            caller.view.forceUpdate()
        }
    }
    remove_file({filenum: index}) {
        let files = this.controller.getState({key: "files"})
        files.splice(index, 1)
        this.controller.setState({key: "files", value: files})
        this.controller.view.forceUpdate()
    }
    handle_doc_delete(file, filenum) {
        this.remove_file({filenum: filenum})
        file.endpoint = this.controller.view.state.file_endpoint
        file.resource_id = file.id
        file.id = this.controller.endpoint_id
        // console.log(file, filenum)
        this.controller.api.upload.file_delete({caller: this, value: file})
        this.controller.view.forceUpdate()
    }
    handle_checkbox(event) {
        let bool = event.target?.checked
        this.controller.setState({key: "private_file", value: bool})
        // console.log(this.controller.view.state.private_file)
        this.controller.view.forceUpdate()
    }
    handle_reset() {
       // console.log("RESETTING")
        this.controller.setState({key: "private_file", value: true})
        this.controller.setState({key: "current_file", value: {name: undefined}})
    }
    handle_close() {
        this.handle_reset()
        this.view.toggle_modal({toggle_state: false})
        this.controller.view.forceUpdate()
    }
    resolve_file_type(value) {
        let file_type = this.view.state.document_types.find((type) => { return type.value === value });
        return file_type?.label
    }

    resovle_file_name(name, existing_documents) {
      //  console.log("DOCUMENT TYPE",this)
        if(name){
        let duplicate_number = 0
    if(existing_documents?.length !== undefined && existing_documents?.length > 0 ){
        for(let i = 0; i < existing_documents.length; i++){
            let reduced_name = existing_documents[i]?.name.split('.', 1)[0]
            if(name === reduced_name){ duplicate_number ++ }
        }
    }
        if(duplicate_number > 0){
            return name + `(${duplicate_number})`
        }else{return name}
    }else{
        return 
    }
    }

    // filter_doc_types() {
    //     // if(this.view.state.filtered) { return }
    //     let filters = this.view.doc_type_filter
    //     let doc_types = this.view.state.document_types
    //     let arr = []
    //     if(filters?.length > 0 && doc_types?.length > 0) {
    //         for(let el of doc_types) {
    //             for(let filter of filters) {
    //                 if(el?.label?.includes(filter)) {
    //                     arr.push(el)
    //                 }
    //             }
    //         }
    //     }
    //     arr.sort((a, b) => {
    //         if (a.label < b.label) { return -1 }
    //         if (a.label > b.label) { return 1 }
    //         return 0
    //     })
    //     console.log("_____ filtered doc types _____", arr)
    //     // arr.map((el) => this.view.state.document_types.push(el))
    //     this.view.state.document_types = arr
    //     // this.view.state.filtered = true
    // }
}