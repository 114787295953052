// GENERAL REACT MODULES
import React from "react";
import { Container, Col, Row, Button } from "react-bootstrap";

// ALL OTHER MODULES
import "styles/generic.css"
import _baseViewComponent from "./_baseViewComponent.jsx"
import { MainConsumer as Consumer } from "controllers/MainContext.jsx"
import LoadingScreen from "../Loading/LoadingScreen/LoadingScreen.jsx"

var attached = false;

class _basePanel extends _baseViewComponent {
    constructor(props) {
        super(props)
        this.state._final_req       = undefined
        this.state._resolve_load    = false
        this.state._is_loading      = false
        this.state._is_mutating     = false
        this.state.is_closeable     = true
        this.state.is_iteratable    = false
        this.panel_controller       = this.state?.panel_controller
        this.panel_controller       = (this.state?.parent?.state?.panel_controller !== undefined) ? this.state?.parent?.state?.panel_controller : (this.state?.parent?.panel_controller !== undefined) ? this.state?.parent?.panel_controller : this.panel_controller 
    }
    renderIframeView() {
        return (
            <div id={this.name}>
                {this.renderTitle()}
                {this.renderClose()}
                {this.loadInnerFrame()}
            </div>
        )
    }
    renderTitle() {
        if (this.state.titles !== undefined) {
            return (
                <div className="panel_title">
                    {this.state.titles}
                </div>
            )
        }
        return (<></>)
    }
    determine_close_title() {
        let close_title = "Close Window"
        if (this.state.close_title !== undefined) {
            close_title = this.state.close_title
            if (this.state.is_iteratable) {
                close_title = "Back"
            }
        }
        return close_title
    }
    renderClose() {
        if (this.state.is_closeable) {
            return (
                <Row className="cota_panel_back_header">
                    <Col/>
                    <Col xs={1}>
                        <Button onClick={this.call_close.bind(this)} className="close_button primary_button_coloring cota">
                            {this.determine_close_title()}
                        </Button>
                    </Col>
                </Row>
            )
        }
        return (<></>)
    }
    offsetWrapper() {
        if (this.state.is_closeable) {
            return "cota_panel_offset"
        }
    }
    call_close() {
	    if (this.state?.close_func !== undefined) {
            this?.state?.parent?.function_wrapper(this.state.close_func)
        } else {
	        console.log(this)
	    }
    }
    loadInnerFrame() {
        return (<></>)
    }
    validateInt(message) {
        // Finish this later. Non essential currenctly
        return message
    }
    componentLoadProps() {
        if (this.state?.panel_params !== undefined) {
            Object.keys(this.state?.panel_params).map((param, index) => {
                this.state[param] = this.state?.panel_params[param]
                return undefined
            })
        }
    }
//    componentDidMount() {
//        if (!attached) {
//            window.addEventListener('message', this.handleProcessEvent);
//            attached = true
//        }
//        this._isMounted = true
//        if (this.controller !== undefined) {
//            if (typeof this.controller.load_data === "function") { 
//                this.controller.load_data()
//            }
//        }
//        this.followDidMount()
//    }
    handleProcessEvent = event => {
        if (event.data !== undefined) {
            const data = event.data;
            try {
                const decoded = JSON.parse(data);
                if (Array.isArray(this.state?.channel_name)) {
                    if (this.state?.channel_name.indexOf(decoded?.channel) > -1) {
                        this.controller?.processEventReaction(decoded)
                    }
                } else {
                    if (decoded?.channel === this.state?.channel_name) {
                        this.controller?.processEventReaction(decoded)
                    }
                }
            } catch (e) { }
        }
    }
    is_loading() {
        if (this.state._is_loading) {
            return "block"
        }
        return "hide"
    }
    render_load_shield() {
        return (
            <LoadingScreen params={{parent: this, controller: this.controller}}/>
        )
    }
    // Should have used protection.
    std_internal() {
        return (
            <>
                {
                    this.render_load_shield()
                }
                {
                    this.renderIframeView()
                }
            </>
        )
    }
    std_canvas() {
        return (
            <div className={"gmap_canvas cota_panel_object_unsized "+this._theme}>
                {this.std_internal()}
            </div>
        )
    }
    determine_canvas() {
        if (this.custom_canvas) {
            return this.renderIframeView()
        }
        return this.std_canvas()
    }
    renderNamedView() {
        return (
            <Consumer className="consumer-wrapper">
                {(context) => {
                    this.state.context = context
                    return (
                        <Container className="generic_100 max_width_row">
                            {this.determine_canvas()}
                        </Container>
                    )
                }}
            </Consumer>
        )
    }
}

export default _basePanel;
