//GENERAL REACT MODULES
import React from "react";
import { Container, Row, Col } from 'react-bootstrap'

//MODULE SPECIFIC

// this appears to be used for the side bar which is not present in the stand alone Loadboost version

// import grafana_config from "../Map/grafana_test.json";
import grafana_config from "assets/grafana_config.json";
import GrafanaFrame from "components/GrafanaFrame/GrafanaFrame.view.jsx";
import _basePanelView from "components/General/_basePanelView.jsx"
import Controller from "./LoadBoost.controller.jsx"
import Modal from "./LoadBoostModal/LoadBoost.modal.view"
import "./LoadBoost.css"
import CotaListPanel from "components/CotaListPanel/CotaListPanel.partial.jsx"
import config_data from "./Loadboost.json"
// we dont need this because overlay is a stritly carrier pro feature
// import Overlay from "./Overlay/Overlay.partial";

export default class LoadBoostPanel extends _basePanelView {
    constructor(props) {
        super(props)
        this._modal     = Modal
        this.controller = new Controller({ params: { view: this } })
        this.state.screen       = 0
        this.state.panelId      = 1
        this.state.panel_name   = "loadboard-2"
        this.state.selected     = undefined
        this.state.channel_name = ["grafana_row", "grafana_button"]
        this.state.panel_configs    = grafana_config["pages"]["loadboard-2"]
        this.handleProcessEvent = this.handleProcessEvent.bind(this)
        this.state.equipment    = []
    }
    // get self() {
    //     return this.state.context.self
    // }
    // async load_user() {
    //     if (this.state.data.user === undefined) {
    //         this.state.data.user = this.controller.getCopy(await this.state?.context?.get_self({caller: this.controller, update: true}))
    //         this.forceUpdate()
    //     }
    // }
    // probably don't need this. so rip this function out and the coresponding OVERLAY folder

    // determine_modal() {
    //     if(this.state?.data?.user?.roles?.length === 0) {
    //         return (
    //             <Overlay params={{parent: this, controller: this.controller}}/>
    //         )
    //     }
    // }
    render_grafana_panel() {
        // being that this is open to the public removeing the gaurd makes sense
        // if(this.state.data.user === undefined || this.state.data.user === null) { return <></> }
        return (
            <CotaListPanel params={{
                parent: this,
                panel_name: this.state.panel_name, subdomain: "boards",
                configs: config_data?.panels,
                endpoint: "loads",
                req_name: "loadboost",
                query: "loadboost",
                configs: config_data.panels,
                base_height: "85vh",
                //query_params: this.controller.generate_query_params(this.state?.page?.attributes),

                // args: this.state?.page?.status_filter[0] === undefined ? `carrier:"${this?.state?.carrier_id}"` : `carrier:"${this?.state?.carrier_id}",statuses:["${this.state?.page?.status_filter}"]` ,
                filters: {
                    name: this.state?.page?.user_filter,
                    // statuses: JSON.stringify(this.state?.page?.status_filter),
                    // ltl_truckload: this.controller.resolve_ltl_toggle(),
                    // nots: ((this.state?.page?.status_filter?.length > 0) ? "" : "status_id"),
                    // carrier_id: this.state?.carrier_id,
                    // time: this.controller.process_date(this.state?.page?.time_frame)


                }
            }} />
        )
    }
    renderMain() {
        // there shouldn't be a user becuse nobody logs in
        // this.load_user()
        return (
            <>
                <Col className="title" >
                    <img  className="profile-photo logo center" src={require("../../assets/cotalogo.png")} alt={"Carlie Anglemire"} />
                    LoadBoost<sup className="trademark" >™</sup>
                </Col>
                <Container className="loadboost_height g-0">
                    <Row className="max_height">
                        <Col id={this.name}>
                            <Row  className="g-0 generic_100 ">
                                {this.render_grafana_panel()}
                            </Row>
                        </Col>
                    </Row>
                    {/* {this.determine_modal()} */}
                </Container>
            </>
        )
    }
}

