// GENERAL REACT MODULES

// ALL OTHER MODULES
import _ModelController from "./_ModelController";

export default class PersonModelController extends _ModelController {
    constructor(props) {
        super(props)
//        this.index = (props.params?.index === undefined) ? 0 : props.params.index
        let get_first_name  = this.get_first_name.bind(this)
        let get_last_name   = this.get_last_name.bind(this)
        this.name = {
            get first() {
                return get_first_name()
            },
            get last() {
                return get_last_name()
            }
        }
    }
    get sum() {
        let filled = 0
        Object.values(this?.data).map((value, index) => {
            if (value !== undefined) {
                filled = filled + 1
            }
        })
        return filled
    }
    load(index) {
        this.index = index
        return this
    }
    is_valid(field) {
        if(field !== null && field !== undefined) {
            if(field.trim() !== "") {
                return true
            }
        }
        return false
    }
    validateContact() {        
        if (this.is_valid(this.data?.first_name) && this.is_valid(this.data?.last_name)) {
            if(this.is_valid(this.email) || this.is_valid(this.phone)) {
                return true
            }
        }
        return false
    }
    get_first_name() {
        return this.data?.first_name
    }
    get_last_name() {
        return this.data?.last_name
    }
    get email() {
        if (this.data?.email !== null && this.data?.email !== undefined) {
            return this.data?.email
        }
        return ""
    }
    set email(data) {
        this.data.email = data
    }
    get phone() {
        return this.data?.phone
    }
    set phone(data) {
        this.data.phone = data
    }
    get roles() {
        return this.data?.roles
    }
    set roles(data) {
        this.data.roles = data
    }
    get first_name() {
        // return this.data?.first_name ?? ""
        if(this.data?.first_name !== undefined && this.data?.first_name !== null) {
            return this.data.first_name
        }
        if(this.data?.given_name !== undefined && this.data?.given_name !== null) {
            return this.data.given_name
        }
        return ""
    }
    set first_name(data) {
        this.data.first_name = data
    }
    get last_name() {
        // return this.data?.last_name ?? ""
        if(this.data?.last_name !== undefined && this.data?.last_name !== null) {
            return this.data.last_name
        }
        if(this.data?.family_name !== undefined && this.data?.family_name !== null) {
            return this.data.family_name
        }
        return ""
    }
    set last_name(data) {
        this.data.last_name = data
    }
    get full_name() {
        return this.first_name+" "+this.last_name
    }
    get summary() {
        let _summary = {
            first_name: this.name.first,
            last_name: this.name.last,
        }
        if (this._id !== undefined && this._id !== null) {
            _summary.id = this._id
        }
        if (this.roles !== undefined) {
            _summary.roles = this.roles
        }
        if (this.email !== undefined) {
            _summary.email = {
                email: this.email
            }
        }
        if (this.phone !== undefined && this.phone !== null) {
            _summary.phone = {
                country_code:1,
                area_code: parseInt(this.phone.substring(0, 3)),
                phone_number: this.phone.substring(3, this.phone.length)
            }
        }
        return _summary
    }
    // Disable for refactor. Hold on Freight Pro update until this is verified
    // get data() {
    //     if (this.index !== null) {
    //             return this.controller.people?.[this?.index]
    //     }
    //     return this.controller.people
    // }
}
