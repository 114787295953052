
// GENERAL REACT MODULES
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import React from "react";

// OBJECT SPECIFIC MODULES

// ALL OTHER MODULES
import _baseComponent from "components/General/_baseComponent";
import "./dropdown.css"

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input type="checkbox"
                    className={(props?.options?.styled !== undefined) ? ((props.isSelected) ? "stylized_checkbox" : "hidden_checkbox" ): ""}
                    checked={props.isSelected}
                    onChange={() => null} />{" "}
                <label style={{color: ((props?.data?.disabled && props?.data?.internal) ? "grey" : "black")}}>
                    {props.label}
                </label>
            </components.Option>
        </div>
    );
};
export class _baseDropdown extends _baseComponent {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    get _key() {
        return (this.state.root === undefined) ? this.state.title : this.state.root
    }
    get _param() {
        return (this.state.root === undefined) ? this.state.name : this.state?.title+"."+this.state?.name 
    }
    get _dropdown_controller() {
        return (this.state?.parent?.modal_controller !== undefined) ? this.state.parent.modal_controller : this.controller
    }
    handleChange(events) {
        let values = []
        if (Array.isArray(events)) {
            events.map((event, index) => {
                values.push(event.value)
                return
            })
        } else {
            values = events.value
        }
        if (this._dropdown_controller?.[this.state.title] === undefined) {
            this._dropdown_controller.setState({key: this._key, param: this._param, value: values})
        } else {
            if (Array.isArray(this._dropdown_controller?.[this.state.title])) {
                this._dropdown_controller[this.state.title][this.state.index].roles = values
            } else {
                let message = {target: {value: values, title: this.state.title, name: this.state.name}}
                this._dropdown_controller.updateValue({message: message})
            }
        }
        this.controller.follow_on_dropdown({key: this._key, param: this._param, value: values})
    }
    get_value() {
        let vl = this._dropdown_controller?.[this?._key]?.[this?._param]
        if (vl !== undefined && this.state.options !== undefined) {
            if (Array.isArray(this.state.options)) {
                if (this.state.options.findIndex(val => String(val?.value).toLowerCase() === String(vl).toLowerCase()) > -1) {
                    return this.state.options[this.state.options.findIndex(val => String(val?.value).toLowerCase() === String(vl).toLowerCase())]
                }
            }
        }
        return undefined
    }
    get_multi_value() {
        let vl = undefined
        if(this._dropdown_controller?.[this?._key] !== undefined) {
            vl = this._dropdown_controller?.[this?._key]?.[this?._param]
        } else {
            vl = this.state.parent.state?.[this?._key]?.[this?._param]
        }
        if (vl !== undefined) {
            if (Array.isArray(vl)) {
                let values = vl.map((item, index) => {
                    let label = this.state.options?.filter((el)=> item === el.value)[0]?.label;

                    if(label){
                        return {value: item, label: label}
                    }

                    return {value: item}
                })
                return values
            }
        }
        return undefined
    }
}
export class MultiSelectDropdown extends _baseDropdown {
    constructor(props) {
        super(props)
        this.state.options?.map((v, i) => {
            v.internal = this.controller?.check_role("INTERNAL_COTA_USER")
            return
        })
        this.state.open = false
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.close_menu.bind(this)
    }
    componentDidMount() {
        document.addEventListener("mousedown", this.close_menu);
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.close_menu);
    }
    close_menu = (event) => {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event?.target)) {
            this.setState({ open: false })
        }
    }
    render() {
        this.state.disabled         = this.props?.params?.disabled
        if (this.state?.options !== undefined) {
            this.state.options.styled   = this.props?.params?.styled
        }

        const customStyles = this.props.params.selectedOptionsWillDisplay ? {} : {
            valueContainer: (base) => ({
              ...base,
              display: 'none',
            }),
          };

        return (
            <div ref={this.wrapperRef} >
                 <ReactSelect 

                 options={this.state?.options}
                            id={this.name}
                            className={"dropdown "+((this.state.disabled) ? "disabled_dropdown" : "")}
                            isMulti
                            styles= {customStyles}
                            classNamePrefix="react-multi-select"
                            isClearable={false}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ Option }}
                            isDisabled={this.state.disabled}
                            onChange={(e) => this.handleChange(e)}
                            allowSelectAll={true}
                            value={this.get_multi_value()}
                            onInputChange={this.handleInputChange}
                            menuIsOpen={this.state.open}
                            onMenuOpen={() => this.setState({ open: true })}
                            onMenuClose={(e) => this.close_menu(e)}
                            menuShouldScrollIntoView={this.props.params.menuShouldScrollIntoView}
                            />
            </div>
        )
    }
}
export class SelectDropdown extends _baseDropdown {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    render() {
        this.state.disabled = this.props.params.disabled
        return (
            <ReactSelect options={this.state?.options}
                            id={this.name}
                            className={"dropdown "+((this.state.disabled) ? "disabled_dropdown" : "")}
                            classNamePrefix="react-select"
                            isClearable={false}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            isDisabled={this.state.disabled}
                            onChange={this.handleChange.bind(this)}
                            allowSelectAll={true}
                            value={this.get_value()}
                            menuShouldScrollIntoView={this.props.params.menuShouldScrollIntoView}/>
        )
    }
}
