// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button } from "react-bootstrap";

// MODULE SPECIFIC IMPORTS
import _baseModal from "components/General/_baseModal.jsx";
import Controller from "./Help.modal.controller.jsx"
import "./Help.modal.css"

export default class HelpModal extends _baseModal {
    constructor(props) {
        super(props)
        this.modal_controller = new Controller({params: {view: this, controller: this?.controller}})
        this.state.draggable = false
        this.state.modal_params   = {
            positioned: "middle_of_panel",   
            // title: "Help",                
            // title_size: "",                     
            closeable: true,                    
            has_overlay: true,                 
            // close_on_outside_click: true,       
            relative_position: "",
            relative_alignment: "",
            x_adjustment: "0px",                
            y_adjustment: "0px",
        }
    }
    // NEED to embed video
    // NEED to have a button like "got it" or something
    renderView() {
        console.log(this)
        return (
            <Row id={this.name}>
                <Col className="modal_size">
                    <Row className="pb-3">
                        <Col className="center_col title">
                            Help
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {/* <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                Cras nisl erat, dapibus eu justo ut, consectetur condimentum enim. Pellentesque porta aliquet nisl, 
                                sed finibus urna scelerisque ac.
                            </p> */}
                        </Col>
                    </Row>
                    <Row className="video_row">
                        <Col>
                            <div>
                                <iframe 
                                    width="560" 
                                    height="315" 
                                    className="embedded_video"
                                    src="https://www.youtube.com/embed/6yBH3srJ8s0?si=X5QmRV5_CUJ58Up1" 
                                    title="YouTube video player" 
                                    frameborder="0" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;" 
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="center_col">
                            <Button className={this._theme} onClick={this.toggle_modal?.bind(this.state.parent, {toggle_state: false})}>
                                Got it
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}