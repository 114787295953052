// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Container, Button } from 'react-bootstrap';

// MODULE SPECIFC IMPORTS
import _basePanelView from "components/General/_basePanelView.jsx"
import Controller from "./ManageUsers.controller.jsx"
import "./ManageUsers.css"
import CotaUserBar from "components/CotaUserBar/CotaUserBar.partial.jsx"
import GrafanaFrame from "components/GrafanaFrame/GrafanaFrame.view.jsx"
import grafana_config from "assets/grafana_config.json"
import UserPanel from "./UserPanel/UserPanel.view.jsx"
import Modal from "./UserModal/User.modal.jsx"
import config_data from "./ManageUsers.json"

export default class ManageUsersPage extends _basePanelView {
    constructor(props) {
        super(props)
        this.controller             = new Controller({params: {view: this}})
        this._modal                 = Modal
        this.state.form             = config_data.form_objects
        this.state.screen           = 0
        this.state.panel_trees      = [{key: 0, name: "user_panel", base: 0}]
        this.state.panels           = [{key: 0, obj: UserPanel, params: {panel_params: {is_closeable: false, close_title: "Back", close_func: "resetScreen", controller: this.controller}}}]
        this.state.panel_name       = "employees"
        this.state.channel_name     = "grafana_row"
        this.state.panel_configs    = grafana_config["pages"]["employees"]
        this.state.roles            = []
        this.state.raw_roles        = []
        this.state.edit_user        = true
    }
    renderGrafanaPanel() {
        if(this.state?.company_id === undefined) {return <></>}
        return (
            <GrafanaFrame params={{ parent: this, panel_configs: this.state.panel_configs, 
                                    panel_name: this.state.panel_name,
                                    filters: {
                                        company_id: this.state?.company_id
                                    }
                                }}
            />
        )
    }
    render_company_search() {
        if(this.controller.internal_user) {
            return this.formObject({data: this.state.form.company, controller: this.partial_controller, owner: this})
        }
        return <></>
    }
    render_new_user() {
        if(this.controller.editable) {
            return (
                <Col>
                    <Button className={"primary_button_coloring " + this._theme} onClick={() => this.controller.new_user()}>
                        New User
                    </Button>
                </Col>
            )
        }
        return <Col />
    }
    renderMain() {
        // TODO: Add a search for name of employee, updated grafana filters with name?? maybe not due to existing panel filters
        // console.log(this)
        return (
            <Container id={this.name} className="generic_100 g-0">
                <Row className="max_height text_color">
                    <Col className="flex_wrapper_rows">
                        <CotaUserBar params={{parent: this, controller: this.controller, title: "Manage Users"}}/>
                        <Row className="generic_100 cota_panel_object g-0 add_shadow">
                            <Col className="generic_100">
                                <Row className="header_row">
                                    <Col className="header_title">
                                        Current Users
                                    </Col>
                                    <Col sm={5}/>
                                    <Col sm={4}>
                                        {this.render_company_search()}
                                    </Col>
                                    {this.render_new_user()}
                                </Row>
                                <Row className="grafana">
                                    <Col>
                                        {this.renderGrafanaPanel()}
                                    </Col>
                                </Row>
                            </Col>                                               
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}