// GENERAL REACT MODULES
import _basePartial from "components/General/_basePartial.jsx"
import { Row, Col } from 'react-bootstrap'

// ALL OTHER MODULES
import Controller from "./CotaListPanel.controller.jsx"
import LineItem from "./LineItem/LineItem.partial.jsx"
import ListHeader from "./ListHeader/ListHeader.partial.jsx"
import FilterLine from "./FilterLine/FilterLine.partial.jsx"
import ListNav from "./ListNav/ListNav.partial.jsx"
import "./CotaListPanel.css"
import "styles/spinner.css"

export default class CotaListPanel extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller     = new Controller({params: {view: this}})
        this.state._header          = (props?.params?.configs?.header === undefined) ? true : props?.params?.configs?.header
        this.state._nav             = (props?.params?.configs?.nav === undefined) ? true : props?.params?.configs?.nav
        this.state._filters         = (props?.params?.configs?.filters === undefined) ? false : props?.params?.configs?.filters
        this.state._nav_btm         = (props?.params?.configs?.nav_btm === undefined) ? true : props?.params?.configs?.nav_btm
        this.state.sort             = (props?.params?.configs?.sort === undefined) ? true : props?.params?.configs?.sort
        this.state.page_length      = 10
        // this.state.page_length      = (this?.main_context?.self?.settings?.row_length === undefined && this?.main_context?.self?.settings?.row_length === null) ? 10 : this?.main_context?.self?.settings?.row_length
        this.state.page             = 1
        this.state.loading          = true
        this.state.self_filters     = []
        this.state.param_filters    = {}
        this.state.half             = (props?.params?.half === undefined) ? false : props?.params?.half
        this.state.base_height      = props?.params?.base_height ?? undefined
        this.state.bordered         = (props?.params?.bordered === undefined) ? false : props?.params?.bordered
        this.state.req_name         = (props?.params?.req_name === undefined) ? "list" : props?.params?.req_name
    }
    componentDidMount() {
        this._isMounted = true
        if (this.partial_controller !== undefined) {
            if (typeof this.partial_controller.load_data === "function") { 
                this.partial_controller.load_data()
            }
        }
        this.state.page_length  = (this?.main_context?.self?.settings?.row_length === undefined || this?.main_context?.self?.settings?.row_length === null) ? 10 : this?.main_context?.self?.settings?.row_length
    }
    scroll_base_height() {
        if (this.state.half) {
            return "50vh"
        }
        if (this.state.base_height !== undefined) {
            return this.state.base_height
        }
        return "100vh"
    }
    render() {
        if(this?.props?.params?.args?.statuses === ""){
            this.props.params.args.statuses = []
        }
        this.state.filters = this.props?.params?.filters
        if (this.state.endpoint !== undefined) {
            this.partial_controller.get_line_items({endpoint: this.state.endpoint, query: this.props?.params?.query, args:this?.props?.params?.args, req_name: this?.props?.params?.req_name })
        }
        let panel_height = (this.parent.panel_height === undefined) ? 0 : this.parent.panel_height + 44
        let sub_panel_height = (this.parent?.subElement?.offsetHeight === undefined) ? 0 : this.parent?.subElement?.offsetHeight
        return (
            <Row id={this.name} ref={ (_selfElement) => { this._selfElement = _selfElement } } className={"generic_100 g-0 "+((this.state.bordered ? "bordered" : ""))}
                    style={{"minWidth": this.partial_controller.get_min_width(), "maxWidth": this.partial_controller.get_max_width()}}>
                <Col className="g-0">
                    {(this.state._nav) ? this.render_nav(): undefined}
                    {this.render_header()}
                    {this.render_filter()}
                    <div style={{"maxHeight": "calc("+this.scroll_base_height()+" - calc("+sub_panel_height+"px + "+(panel_height + this.partial_controller.determine_offset())+"px))", height: "calc("+this.scroll_base_height()+" - calc("+sub_panel_height+"px + "+(panel_height + this.partial_controller.determine_offset())+"px))"}} 
                        className={"scroll_window "+((this.state.bordered ? "scroll_bordered" : ""))} ref={ (_scroll_window) => { this._scroll_window = _scroll_window } } >
                        {this.render_line_items()}
                    </div>
                    {(this.state._nav_btm) ? this.render_nav(true): undefined}
                </Col>
            </Row>
        )
    }
    render_header() {
        if (this.state._header) {
            return <ListHeader params={{parent: this.state.parent,
                                controller: this.partial_controller,
                                actions: this.state.configs?.actions,
                                line_filter: this.state.configs?.filterable,}}
                                className="g-0"/>
        }
    }
    render_nav(is_bottom) {
        return <ListNav params={{parent: this.state.parent, is_bottom: is_bottom,
                        controller: this.partial_controller}}
                        className="g-0"/>
    }
    render_filter() {
        if (this.state._filters) {
            return <FilterLine params={{parent: this.state.parent,
                                controller: this.partial_controller,
                                actions: this.state.configs?.actions,
                                line_filter: this.state.configs?.filterable}}
                                className="g-0"/>
        }
    }
    render_line_items() {
        if (Array.isArray(this.state.line_items)) {
            let line_items = this.partial_controller.filter_data(this.state.line_items)
            // if (this.state.sort_by !== undefined) {
            //     line_items = this.partial_controller?.sort_by({line_items: line_items, sort_by: this.state.sort_by})
            // }
            return line_items?.map((item, index) => {
                return <LineItem params={{parent: this.state.parent,
                                            controller: this.partial_controller, 
                                            is_draggable: this.state.configs?.moveable,
                                            external_drag: !this.state.configs?.move_internal,
                                            is_clickable: this.state.configs?.clickable,
                                            line_filter: this.state.configs?.filterable,
                                            actions: this.state.configs?.actions,
                                            height: this.state.configs?.height,
                                            index: index,
                                            item: item}} 
                                            key={"line_item_"+index}/>
            })
        }
    }
}
