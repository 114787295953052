// ALL OTHER MODULES
import _baseApiObject from "components/General/_baseApiObject";
import drivers_request from "assets/graphql/Companies/Drivers.graphql.json"
import test_data from "./test.json"
import schema from "./schema.json"

export default class Drivers extends _baseApiObject {
    constructor(props) {
        super(props)
        this.schema     = schema
        this.test_data  = test_data
        this.requests   = {
            list: drivers_request,
        }
    }
}