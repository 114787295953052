// GENERAL REACT MODULES
import * as React from 'react';
import "./LoadingScreen.css"
import { Row, Col } from 'react-bootstrap';

export default class LoadingScreen extends React.Component {
    constructor(props) {
        super(props)
        this.parent = this.props?.params?.parent
        this.controller = this.props?.params?.controller
    }
    render() {
        return (
            <Row className={"load_shield "+this.parent.is_loading()}>
                <Col className="generic_100">
                    <Row className="loader_row">
                        <Col className='center_content'>
                            <div id="loader_title">Cota Systems</div>
                        </Col>
                    </Row>
                    <Row className="loader_row">
                        <Col>
                            <div id="loader"></div>
                        </Col>
                    </Row>
                    <Row className="loader_row">
                        <Col>
                            &nbsp;
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}