import React from "react";
import { FaTruck } from "react-icons/fa";
import LoadBoostPanel from "../pages/LoadBoost/LoadBoost.view.jsx";
import JotForm from "../pages/JotForm/JotForm.view.jsx";

const mContext = {
    menus: [[{name: "Available Loads", icon: <FaTruck className="menuListIcon" />, internal: true, link: "/loadboard", }]],
    
    pages: {
            "/": {link: "/", page: <LoadBoostPanel />},
            "/loadboard": {link: "/loadboard", page: <LoadBoostPanel/>},
            "/jot": {link: "/jot", page: <JotForm />}  
           },
    default: "/loadboard"
};

mContext.getMenu = function () {
  return mContext.menus;
};

mContext.getPages = function () {
  return mContext.pages;
};

mContext.getPage = function ({ link, params }) {
  return mContext.pages[link].page;
};

const ModuleContext = React.createContext(mContext);

export const ModuleProvider = ModuleContext.Provider;
export const ModuleConsumer = ModuleContext.Consumer;

export default ModuleContext;
