// GENERAL REACT MODULES
import _viewController from "controllers/ViewController/_viewController.jsx";
import { Menu, MenuItem } from 'react-pro-sidebar';

import SmallIconImage from "assets/CotaSystemsLogo_Vertical_White.png"
import WideIconImage from "assets/logo.png"
// import self_request from "assets/graphql/Users/self.graphql.json"

export default class SidebarController extends _viewController {
    constructor(props) {
        super(props)
        this._ = undefined
        // this.requests   = [{request: self_request, callback: {f: this.load_user, p: {name: "self"}}, endpoint: "users"}]
    }
    toggle_menu() {
        this.setState({key: "menuCollapse", param: undefined, value: !this.getState({key: "menuCollapse"})})
        this.setState({display: "block"})
    }
    load_user({caller, params, results}) {
        let target = (params?.name === "users") ? "updateSelf" : "self"
        if(results?.errors === undefined && results?.data?.[target] !== undefined) {
            caller.view.state.data.user = caller.getCopy(results.data[target])
            caller.view.state.data.user_copy = caller.getCopy(results.data[target])
            caller.view.state.cache = caller.getCopy(caller.view.state.data.user)
            caller.view.forceUpdate()
        }
    }
//    menuIconClick = () => {
//        this.setState({key: "menuCollapse", param: undefined, value: !this.getState({key: "menuCollapse"})})
//    }
    menuIconClick = () => {
        this.context.self.settings.menu_open = !this?.context?.self?.settings?.menu_open
        this.toggle_sidebar()
    }
    toggle_sidebar() {
        console.log("Toggle",this.context.self.settings.menu_open)
        //  this.context.self.settings.menu_open = this?.context?.self?.settings?.menu_open
        console.log("Toggle2",this.context.self.settings.menu_open)
       this.setState({key: "menuCollapse", param: undefined, value: !this.getState({key: "menuCollapse"})})
    //    this.view.load_self(this.context)
    }
    checkActiveMenuItem(menu_item) {
        if (menu_item?.link !== undefined) {
            return (menu_item?.link === window.location.pathname) ? "activeMenuItem hightlight_sidebar_color" : "inActiveMenuItem"
        }
        return "inActiveMenuItem"
    }
    addBorders(menu_item) {
        let border_style = {}
        if (menu_item?.borders !== undefined) {
            menu_item?.borders.map(border => (
                    border_style["border"+border] = "1px solid #73a2d7"
                )
            )
        }
        return border_style
    }
    get_title() {
        if (window.location.href.includes("freightpro")) {
            return (
                <>
                    <span className="title_text">
                        FreightPro
                    </span>
                    <span className="superscript">
                        TM&nbsp; 
                    </span>
                </>
            )
        }
        if (window.location.href.includes("carrierpro")) {
            return (
                <>
                    <span className="title_text">
                        CarrierPro
                    </span>
                    <span className="superscript">
                        TM&nbsp; 
                    </span>
                    <span className="title_text">
                        &nbsp;TMS
                    </span>
                </>
            )
        }
        return "Cota Transportation"
    }
    check_gate(menu_item) {
        let caller = (this?.view !== undefined) ? this.view : this
        if(caller.state.context.self?.roles?.length <= 0) {
            let def = caller?.context?.default
            if(def !== undefined) { 
                menu_item = (caller.context.menus.filter((menu) => menu?.[0]?.link === def))?.[0]?.[0]
            }
        }
        return menu_item
    }
    _handleClick(menu_item, props) {
        let caller = (this?.view !== undefined) ? this.view : this
        caller.state.display = "none"
        menu_item = caller.controller.check_gate(menu_item)
        if (menu_item?.func !== undefined) {
            if(typeof menu_item.func === "function") {
                menu_item.func(this)
            }
        }
        if (menu_item !== undefined) {
            if (menu_item?.internal) {
                if (menu_item?.binding !== undefined) {
                    caller.setState({[menu_item?.binding]: !caller?.state?.[menu_item?.binding]})
                }
                window.history.pushState('menuSelection', 'Title', menu_item?.link);
                this.setState({selection: menu_item?.link})
                let paths = caller.controller.updatePaths()
                caller.state.parent.controller.paths = paths
                props.selection = menu_item?.link
                if (caller.state?.context?.page !== undefined) {
                    caller.state.context.page.setState({path: -1})
                }
                caller.state?.parent.forceUpdate()
            } else {
                if(this.state === undefined) {
                    caller.state?.context.authClient?.logout()
                } else {
                    this.state?.context.authClient?.logout()
                }
            }
        }
    }
    expand_settings(caller) {
        caller.setState({expanded: !caller.state.expanded})
    }
    generate_row_item({roles, menu_item, index}) {
        let display = false
        if (menu_item?.requirements === undefined) {
            display = true
        } else {
            if (Array.isArray(menu_item.requirements.roles)) {
                menu_item.requirements.roles.forEach((role, index) => {
                    if (roles !== undefined) {
                        if (roles.includes(role)) {
                            display = true
                        }
                    }
                })
            }
        }
        if (menu_item?.bound !== undefined) {
            display = this.view.state[menu_item.bound]
        }
        if (display) {
            return (
                <span key={menu_item.name} 
                    className={this.checkActiveMenuItem(menu_item)}
                    style={this.addBorders(menu_item)} >
                        <Menu iconShape="square" className={"sidebar_icon_color "+((menu_item.bound !== undefined) ? "pad_left" : "")}>
                            <MenuItem icon={menu_item.icon} >
                                {menu_item.name}
                                {/* NEED to create a full view for feedbear with feature cards, this is a temp ternary */}
                                {(menu_item?.integration === "feedbear") ? <a data-feedbear-button /> :
                                <a href="#" onClick={this._handleClick.bind(this.view, menu_item, this.view.context)}/>}
                            </MenuItem>
                        </Menu>
                </span>
            )
        }
    }
    get_icon() {
        if (this?.view?.collapsed) {
            return <img src={SmallIconImage} className="sidebar_icon_col" alt="Cota Logo Small"/>
        } else {
            return <img src={WideIconImage} className="center_item" alt="Cota Logo Wide"/>
        }
    }
    process_user_load({caller, params, results}) {
        caller.view.setState({loading: false,
                        menuCollapse: !results?.data?.self?.settings?.menu_open})
    }
}