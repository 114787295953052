// GENERAL REACT MODULES
import _basePartial from "components/General/_basePartial.jsx"
import { Row, Col } from 'react-bootstrap'
import Draggable from 'react-draggable';

// ALL OTHER MODULES
import "./LineItem.css"
import Controller from "./LineItem.controller.jsx"

export default class LineItem extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller     = new Controller({params: {view: this}})
        this.state.hover            = (this.state.hover === undefined) ? false : this.state.hover
        this.state.is_draggable     = (this.state.is_draggable === undefined) ? false : this.state.is_draggable
        this.state.is_moving        = (this.state.is_moving === undefined) ? false : this.state.is_moving
        this.state.is_clickable     = (this.state.is_clickable === undefined) ? false : this.state.is_clickable
        this.state.external_drag    = (this.state.external_drag === undefined) ? true : this.state.external_drag
        this.state.line_fitler      = (this.state.line_fitler === undefined) ? false : this.state.line_fitler
        this.state.hovered          = false
    }
    get items() {
        return this.controller.getState({key: "line_items"})
    }
    get item_data() {
        return this.props?.params?.item
        // return this.items?.[this.state?.index]
    }
    get line_item() {
        return this.partial_controller.line_item
    }
    get line_height() {
        if (this._configs?.columns?.has_icons?.length > 0) {
            return 70
        }
        if (this._configs?.columns?.tri?.length > 0 || this._configs?.columns?.dual?.length > 0) {
            return 60
        }
        return 40
    }
    get _configs() {
        return this.controller.getState({key: "configs"})
    }
    render() {
	    this.state.width = this.frame.width
        this.state.data = this.item_data
        return (
            <Row id={this.name} style={{height: this.line_height, "lineHeight": this.line_height+"px"}} 
                                className={" font_style g-0 "+((this.state.index % 2 === 0) ? "odd_row" : "even_row")}
                                ref={ (_selfElement) => { this._selfElement = _selfElement } }>
                <Col>
                    {this.determine_draggable()}
                </Col>
            </Row>
        )
    }
    determine_draggable() {
        if (this.state?.is_draggable) {
            return (
                <Draggable nodeRef={ this.nodeRef } position={{ x: 0, y: 0 }} ref={ (_selfElement) => { this._selfElement = _selfElement } } 
                                        onStart={(e) => this.partial_controller.eventHandler(e, "start")}
                                        onDrag={(e) => this.partial_controller.eventHandler(e, "drag")} 
                                        onStop={(e) => this.partial_controller.eventHandler(e, "stop")}
                                        disabled={false}>
                    {this.renderLine()}
                </Draggable>
            )
        } else {
            return this.renderLine()
        }
    }
    renderLine() {
        return (
            <Row className={"center_only color_row g-0 "+this.partial_controller.external_draggable()+" "+this.partial_controller.moving_class()+" "+this.controller.get_hover_class(this.state.hover)+" "+this.partial_controller.touchable()}
                                onClick={this.partial_controller.handle_row.bind(this)} style={{ width: this.partial_controller.moving_width(), top: this.state.offset}}
                                onMouseEnter={this.partial_controller.hover_line.bind(this, true)} onMouseLeave={this.partial_controller.hover_line.bind(this, false)}>
                {this.partial_controller?.map_columns()}
                {this.partial_controller?.add_button()}
                {this.partial_controller?.add_actions()}
                {this.partial_controller?.self_filter()}
            </Row>
        )
    }

}
