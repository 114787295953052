// GENERAL REACT MODULES

// ALL OTHER MODULES
import _ModelController from "../_ModelController";
import remappers from "./CommodityRemapper.json"

export default class CommodityModelController extends _ModelController {
    constructor(props) {
        super(props)
        this._state = undefined
        this.remappers = remappers
        // if (this.index !== undefined) {
        //     this._param = "commodities"
        // }
    }
    get commodity() {
        return this.data?.commodity
    }
    set commodity(value) {
        this.controller.setState({key: this._data_key, param: value})
    }
    get name() {
        if (this.data?.name === undefined || this.data?.name === null) {
            return ""
        }
        return this.data?.name?.charAt(0).toUpperCase() + this.data?.name?.slice(1);
    }
    set name(value) {
        this.data.name = value
    }
    get description() {
        return this.data?.description
    }
    get volume() {
        if (!isNaN(parseInt(this.width)) && !isNaN(parseInt(this.height)) && !isNaN(parseInt(this.length))) {
            return parseInt(this.width) * parseInt(this.height) * parseInt(this.length)
        }
        return 0
    }
    set description(value) {
        this.data.description = value
    }
    get value() {
        return this.data.value
    }
    set value(value) {
        this.data.value = value
    }
    get weight() {
        return this.data?.weight
    }
    set weight(value) {
        this.data.weight = value
    }
    get type() {
        return this.data?.type
    }
    set type(value) {
        this.data.type = value
    }
    get quantity() {
        return this.data?.quantity
    }
    set quantity(value) {
        this.data.quantity = value
    }
    get piece_count() {
        return this.data?.piece_count
    }
    set piece_count(value) {
        this.data.piece_count = value
    }

    get raw_length() {
        return this.data?.length
    }
    get length() {
        return this.data?.length
    }
    set length(value) {
        this.data.length = value
    }

    get raw_width() {
        return this.data?.width
    }
    get width() {
        return this.data?.width
    }
    set width(value) {
        this.data.width = value
    }

    get height() {
        return this.data?.height
    }
    get raw_height() {
        return this.data?.height
    }
    set height(value) {
        this.data.height = value
    }

    get class() {
        return this.data?.class
    }
    set class(value) {
        this.data.class = value
    }
    get nmfc() {
        return this.data?.nmfc
    }
    set nmfc(value) {
        this.data.nmfc = value
    }

    get oversized() {
        return this.data?.oversized
    }
    set oversized(value) {
        this.data.oversized = value
    }
    get hazmat() {
        return this.data?.hazmat
    }
    set hazmat(value) {
        this.data.hazmat = value
    }
    get pallets_exchange() {
        return this.data?.pallets_exchange
    }
    set pallets_exchange(value) {
        this.data.pallets_exchange = value
    }
    get cases() {
        return this.data?.cases
    }
    set cases(value) {
        this.data.cases = value
    }
    get pallets() {
        return this.data?.pallets
    }
    set pallets(value) {
        this.data.pallets = value
    } 
    get temperature_range() {
        return this.data?.temperature_range
    }
    get temp_min() {
        return this.data?.temp_min
    }
    set temp_min(value) {
        this.data.temp_min = value
    }
    get temp_max() {
        return this.data?.temp_max
    }
    set temp_max(value) {
        this.data.temp_max = value
    }
    get container_number() {
        return this.data.container_number
    }
    set container_number(value) {
        this.data.container_number = value
    }

    get summary() {
        let data = JSON.parse(JSON.stringify(this.data))
        if(this._notnull(data?.class) && typeof data.class === "number") {
            data.class = data.class.toString()
        }
        if (isNaN(this.weight)) {
            delete data.weight
        } else {
            data.weight = Number(data.weight)
        }
        if (isNaN(this.quantity)) {
            delete data.quantity
        } else {
            data.quantity = Number(data.quantity)
        }
        if (isNaN(this.piece_count)) {
            delete data.piece_count
        } else {
            data.piece_count = Number(data.piece_count)
        }
        if (isNaN(this.length)) {
            delete data.length
        } else {
            data.length = Number(data.length)
        }
        if (isNaN(this.width)) {
            delete data.width
        } else {
            data.width = Number(data.width)
        }
        if (isNaN(this.height)) {
            delete data.height
        } else {
            data.height = Number(data.height)
        }

        if (isNaN(this.cases)) {
            delete data.cases
        } else {
            data.cases = Number(data.cases)
        }
        if (isNaN(this.pallets)) {
            delete data.pallets
        } else {
            data.pallets = Number(data.pallets)
        }
        
        if(isNaN(this.temp_min) || isNaN(this.temp_max)) {
            delete data.temp_min
            delete data.temp_max
        } else {
            data.temperature_range = this.temp_min + " - " + this.temp_max
        }
        delete data.temp_min
        delete data.temp_max

        if (this.value !== null && this.value !== undefined) {
            data.value = Number(data.value)
        } else {
            delete data.value
        }
        
	    return data
    }
}
